<template>
  <div class="ig-sidebar">
    <div class="more" :class="{ openMore: !showMore }" @click="clickMore">
      <img src="@img/educate/side.png" alt="" />
    </div>
    <div class="list" :class="{ showMore: showMore }">
      <p class="line1"></p>
      <template v-for="(item, index) in menu" :key="index">
        <div
          v-if="item.show || is_admin == 1"
          class="item"
          :class="{
            active: activeBar == item.type,
          }"
          @click="doAction(item)"
        >
          <img :src="item.icon" alt="" />
          <p class="name">{{ item.name }}</p>
        </div>
      </template>
      <p class="line1"></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeBar: 1,
      is_admin: 0,
      showMore: true,
    };
  },
  created() {
    let path = this.$route.path;
    let c = path.indexOf("marketing");
    if (c !== -1) this.getMClueauth();
    this.activeBar = this.type;
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
    menu: {
      type: Array,
      default: [
        {
          name: "销售线索",
          icon: require("@img/marketing/m1.png"),
          toLink: false,
          path: "/pc_marketing",
          show: true,
          type: 1,
        },
        {
          name: "线索池",
          icon: require("@img/marketing/m2.png"),
          show: false,
          path: "/pc_marketing",
          toLink: false,
          type: 2,
        },
        {
          name: "客户",
          icon: require("@img/marketing/m3.png"),
          path: "/pc_marketing",
          show: true,
          toLink: false,
          type: 4,
        },
        {
          name: "公海池",
          icon: require("@img/marketing/m4.png"),
          show: true,
          path: "/pc_marketing",
          toLink: false,
          type: 3,
        },

        {
          name: "统计",
          icon: require("@img/marketing/m5.png"),
          toLink: true,
          show: true,
          path: "/statistics",
        },
        {
          name: "报表",
          icon: require("@img/marketing/m12.png"),
          toLink: false,
          show: false,
          path: "/pc_marketing",
          type: 5,
        },
        {
          name: "外勤",
          icon: require("@img/marketing/m10.png"),
          toLink: true,
          show: true,
          path: "fieldWork",
        },
        {
          name: "待办",
          icon: require("@img/marketing/m6.png"),
          toLink: true,
          show: true,
          path: "agent",
        },
      ],
    },
  },

  watch: {
    type: {
      handler(newVal, oldVal) {
        this.activeBar = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    //2 营销
    getMClueauth() {
      this.$api.getMClueauth().then((res) => {
        this.is_admin = res.data.is_admin;
        this.$local.set("is_admin", this.is_admin);
        this.$local.set("alloc_pool", JSON.stringify(res.data.alloc_pool));
      });
    },
    doAction(item) {
      if (item.toLink) {
        this.$router.push(item.path);
      } else {
        this.activeBar = item.type;
        this.$router.push({
          path: item.path,
          query: {
            type: item.type,
          },
        });
        this.$emit("doAction", item.type);
      }
    },
    changeMenu(path) {
      this.fullPath = path;
      this.$router.push("/pc_marketing?type=5");
    },
    clickMore() {
      this.showMore = !this.showMore;
      this.$emit("clickMore");
    },
  },
};
</script>

<style lang="scss" scoped>
.ig-sidebar {
  background: #ffffff;
  border-radius: 10px 0 0 10px;
  z-index: 99;
  box-shadow: 0px 0px 9px 0px rgba(60, 60, 60, 0.18);
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  .more {
    position: absolute;
    font-size: 16px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    left: -40px;
    width: 40px;
    height: 80px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background: #ffffff;
    box-shadow: -2px 0px 4px 0px rgba(60, 60, 60, 0.18);
    z-index: 9;
    cursor: pointer;
    & > img {
      height: 100%;
    }
  }
  .openMore {
    left: -40px;
  }
  .list {
    opacity: 0;
    width: 0;
    overflow: hidden;
    transition: all 0.4s linear;
    .line1 {
      height: 10px;
    }
    .item {
      opacity: 0.2;
      cursor: pointer;
      position: relative;
      @include flex-center(column);

      & > img {
        width: 60px;
      }
      & > p {
        font-size: 16px;
      }
      .name {
        position: absolute;
        right: 84px;
        padding: 4px 0;
        border-radius: 10px;
        width: 0;
        overflow: hidden;
        background: #ffffff;
        text-align: center;
        opacity: 0.5;
        box-shadow: 0px 0px 9px 0px rgba(60, 60, 60, 0.18);
        transition: all 0.1s linear;
        white-space: nowrap;

        z-index: 99;
      }
      &:hover {
        opacity: 1;
        .name {
          width: 80px;
          opacity: 1;
        }
      }
    }
    .active {
      opacity: 1;
    }
    .item:not(:nth-last-child(2)) {
      margin-bottom: 10px;
    }
  }
  .showMore {
    opacity: 1;
    width: 80px;
    overflow: visible;
  }
}
</style>
