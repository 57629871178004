<template>
  <div class="">
    <vxe-modal v-model="show" id="contractModal" width="800" height="600" resize remember storage transfer @close="closed" destroy-on-close>
      <template #title>
        <span>生成合同编号</span>
      </template>
      <template #default>
        <div class="action-box px-4">
          <div class="flex">
            <div class="label mt-2">
              <span class="title mr-4">线索池</span>
              <vxe-select v-model="clueId" placeholder="请选择线索池" clearable @change="getNumberList">
                <vxe-option v-for="(item, index) in salecluePool" :key="index" :value="item.id" :label="item.name"></vxe-option>
              </vxe-select>
            </div>
            <div class="label mt-2">
              <span class="title mr-4">合同类型</span>
              <vxe-select v-model="conType" placeholder="请选择合同类型" clearable @change="getNumberList">
                <vxe-option v-for="(item, index) in conTypeList" :key="index" :value="item.id" :label="item.name"></vxe-option>
              </vxe-select>
            </div>
          </div>

          <div class="label mt-2">
            <span class="title">是否含税</span>
            <el-radio-group v-model="isTaxi" class="ml-4">
              <el-radio label="T">是</el-radio>
              <el-radio label="N">否</el-radio>
            </el-radio-group>
          </div>
          <div class="number-list mt-2">
            <span class="title">历史合同编号</span>
            <div class="copy" ref="copy" :data-clipboard-text="copyData"></div>
            <div class="list px-2" v-if="numberList.length > 0">
              <div class="item py-1" v-for="(item, index) in numberList" :key="index">
                <span> {{ item.contract_sn }}</span>
                <div class="ml-2">
                  <vxe-button status="danger" @click="delNum(item.id)">删除</vxe-button>
                  <vxe-button status="info" @click="copyNum(item.contract_sn)">复制</vxe-button>
                </div>
              </div>
            </div>
            <div class="noData" v-else>无历史合同编号</div>
          </div>
          <div class="invalid-btn mt-2">
            <vxe-button type="reset" @click="closed">取消</vxe-button>
            <!-- :disabled="numberList.length >= 5" -->
            <vxe-button type="submit" status="primary" @click="enterClick">生成合同编号</vxe-button>
          </div>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
import debounce from "@/assets/js/debounce.js";
import Clipboard from "clipboard";
export default {
  data() {
    return {
      show: true,
      isTaxi: "T",
      numberList: [],
      salecluePool: [],
      clueId: 0,
      copyData: "",
      conType: 10,
      conTypeList: [
        { id: 10, name: "销售合同" },
        { id: 20, name: "渠道合同" },
      ],
    };
  },
  created() {
    this.getSalecluePool(0);
  },
  computed: {
    formatDate() {
      let _this = this;
      return function (val) {
        return _this.$utils.formatDate(val, "yyyy-MM-dd hh:mm");
      };
    },
  },
  props: {},
  watch: {},
  mounted() {
    const clipboard = new Clipboard(".copy");
    clipboard.on("success", (e) => {
      e.clearSelection();
      return this.$XModal.message({
        content: "复制成功",
        status: "success",
      });
    });
    clipboard.on("error", (e) => {
      console.log("复制失败", e);
    });
  },

  methods: {
    enterClick() {
      debounce(this.createNumber, 500); //防抖
    },
    copyNum(val) {
      this.copyData = val;
      setTimeout(() => {
        this.$refs.copy.click();
      }, 200);
    },
    // 线索池
    getSalecluePool(val) {
      // 0/1/2
      // 1全部 2
      this.$api.getSalecluePool({ isAll: val }).then((res) => {
        this.salecluePool = res.data;
        if (this.salecluePool.length > 0) this.clueId = this.salecluePool[0].id;
        this.getNumberList();
      });
    },
    createNumber() {
      this.$api.createNumApi({ clueId: this.clueId, isTaxi: this.isTaxi, contract_type: this.conType }).then((res) => {
        this.$XModal.message({
          content: "创建成功",
          status: "success",
        });
        this.getNumberList();
      });
    },
    getNumberList() {
      this.$api.numListApi({ clueId: this.clueId, contract_type: this.conType }).then((res) => {
        this.numberList = res.data;
      });
    },
    delNum(id) {
      this.$api.delNumApi({ id: id }).then((res) => {
        this.$XModal.message({
          content: "删除成功",
          status: "success",
        });
        this.getNumberList();
      });
    },
    closed() {
      this.$emit("closed");
    },
  },
};
</script>

<style lang="scss" scoped>
.action-box {
  .flex {
    @include flex-between();
    & > .label {
      width: 50%;
    }
  }
  .number-list {
    min-height: 300px;
    .noData {
      padding: 80px 0;
      color: #e1e1e1;
      text-align: center;
    }
  }
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .invalid-btn {
    @include flex-center();
  }
  .list {
    .item {
      @include flex-between();
      .sec-title {
        @include flex-between();

        & > p {
          font-size: 20px;
          color: #a4a4a4;
        }
        & > div {
          .img-box {
            width: 40px;
            height: 40px;
            background: #f7f8fa;
            overflow: hidden;
            border-radius: 50%;
            & > img {
              width: 100%;
            }
          }
          @include flex-between();
          font-size: 20px;
          color: #909090;
          span {
            color: #4892fe;
          }
        }
      }
      .desc {
        font-size: 18px;
        color: #909090;
        line-height: 28px;
      }
      .delete-btn {
        @include flex-end();
        & > p {
          font-size: 18px;
          color: #f34343;
        }
      }
    }
    .item:not(:last-child) {
      border-bottom: 1px solid#F7F8FA;
    }
  }
}
</style>
