<template>
  <div class="ig-container pc-container pl-2" :class="{ 'pr-2': !showMore }">
    <div class="backMobile">
      <span @click="backMobile" title="返回移动端" class="mr-1 back"></span>
      <span title="清除缓存" @click="clearCache" class="del"></span>
    </div>
    <customer-table v-if="type == 4" class="mytable" :class="{ noSlide: !showMore }"></customer-table>
    <census class="mytable" v-else-if="type == 5" :class="{ noSlide: !showMore }"></census>
    <pc-table v-else class="mytable" :class="{ noSlide: !showMore }" :source="type"></pc-table>

    <sidebar class="myslide" :class="{ showMore: !showMore }" :type="type" @clickMore="clickMore" @doAction="doAction"></sidebar>
  </div>
</template>

<script>
import sidebar from "@/components/marketing/pc_sidebar.vue";
import pcTable from "@/components/marketing/pc_table.vue";
import customerTable from "@/components/marketing/customer_table.vue";
import Census from "@/components/marketing/census.vue";
export default {
  components: {
    sidebar,
    pcTable,
    customerTable,
    Census,
  },

  data() {
    return {
      fullscreen: false,
      showMore: true,
      type: this.$route.query.type || 1,
    };
  },
  created() {},
  watch: {
    "$route.query.type": {
      handler(newVal, oldVal) {
        //判断newVal有没有值监听路由变化
        this.type = newVal;
      },
      deep: true,
    },
  },
  methods: {
    clearCache() {
      this.$local.clear();
      this.$XModal.message({
        content: `清除成功`,
        status: "success",
      });
      this.$router.go(0);
    },
    backMobile() {
      this.$router.replace("/marketing?back=noback");
    },
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    clickMore() {
      this.showMore = !this.showMore;
    },
    doAction(e) {
      this.type = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.ig-container {
  position: relative;
  min-height: 100vh;
  .backMobile {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 18px;
    color: #538ff8;
    @include flex-start();
    align-items: center;
    & > span {
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: inline-block;
    }
    .back {
      background: url("~@img/marketing/back.png") no-repeat;
      background-size: cover;
    }
    .del {
      background: url("~@img/marketing/del.png") no-repeat;
      background-size: cover;
    }
  }
  @include flex-between();
  .mytable {
    width: 94%;
    transition: all 0.4s linear;
  }
  .noSlide {
    width: 100%;
  }
  .myslide {
  }
}
</style>
