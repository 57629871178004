<template>
  <div class="ig-list-box">
    <div class="title" :class="type == 1 ? 'titleA' : ''">
      <span class="list-title">{{ datas.title }}</span>
      <span class="title-l mr-1" v-if="datas.test">{{ datas.test }} {{ datas.title == "有效率" || datas.title == "点击率" || datas.title == "进线率" ? "%" : "" }}</span>
    </div>
    <div class="list-data" v-for="(item, index) in datas.lists" :key="index" @click="dept(item.dept_id, datas.title, item.report_avg, item.report_sum)">
      <span class="list-data-l">{{ item.name }}</span>
      <template v-if="datas.title != '已导入'">
        <div class="list-data-r" v-if="datas.title != '未导入'">
          <span class="color" v-if="datas.title == '有效率' || datas.title == '点击率' || datas.title == '进线率'">
            {{ (parseFloat(item.report_avg) * 100).toFixed(2) + "%" || parseFloat(item.report_sum).toFixed(2) }}
          </span>
          <span class="color" v-else>{{ item.report_sum != undefined ? parseFloat(item.report_sum) : parseFloat(item.report_avg) }}</span>
          <span class="iconfont">&#xe624;</span>
        </div>
      </template>
    </div>
    <div class="flex align-center justify-center mt-5" v-if="datas.lists.length == 0">
      <span class="nodata">暂无数据</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    datas: {
      type: Object,
    },
    right: {
      type: String,
    },
  },
  data() {
    return {
      type: 1,
    };
  },
  methods: {
    dept(id, title, nub, nub1) {
      nub = nub ? nub : nub1;
      this.$emit("deptId", id, title, nub);
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
.ig-list-box {
  min-height: 230px;
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 40px;
  .nodata {
    font-size: 0.24rem;
    color: #999580;
  }
 

  .title-l {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
  }

  .title-r {
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #3278d2;
  }

  .color {
    color: #3278d2;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7f8fa;
    padding: 0 20px;
    color: #4f4f4f;
  }

  .titleA {
    background: #005cd1;
    color: #ffffff;
  }

  .list-title {
    font-size: 21px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 61px;
  }

  .list-data {
    border-top: 1px solid #f2f2f2;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .list-data-l {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #4f4f4f;
    }

    .list-data-r {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #b4b4b4;
    }

    .title-r {
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #3278d2;
    }
  }

  .list-data:nth-child(0) {
    border-top: 1px solid transparent;
  }
}
</style>
