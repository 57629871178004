<template>
  <div class="ig-table">
    <vxe-grid
      :loading="loading"
      ref="xGrid"
      v-bind="gridOptions"
      @edit-closed="editClosedEvent"
      @toolbar-button-click="toolbarButtonClickEvent"
      :cell-style="cellStyle"
      @filter-change="filterMethod"
      @sort-change="sortMethod"
    >
      <template #search_item>
        <div class="search-box">
          <vxe-input class="mr-1 input-box" v-model="searchInfo.keyword" placeholder="输入线索客户姓名、公司、电话" clearable></vxe-input>
          <vxe-select class="mr-1" v-model="pool_id" placeholder="请选择线索池" clearable @change="getClueList">
            <vxe-option v-for="(item, index) in salecluePool" :key="index" :value="item.id" :label="item.name"></vxe-option>
          </vxe-select>

          <vxe-input class="mr-1" v-model="searchInfo.follower_temp" placeholder="请选择负责人" @focus="focusEvent" @clear="clearFollower" clearable></vxe-input>
          <el-date-picker
            class="mr-1"
            v-model="searchInfo.clue_time"
            type="daterange"
            unlink-panels
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            range-separator="-"
            start-placeholder="进线时间"
            end-placeholder="进线时间"
          />
          <el-date-picker
            class="mr-1"
            v-model="searchInfo.sign_time"
            type="daterange"
            unlink-panels
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            range-separator="-"
            start-placeholder="签单时间"
            end-placeholder="签单时间"
          />
        </div>
      </template>
      <template #submit_item>
        <vxe-button type="submit" status="primary" content="查询" @click="clickSearch"></vxe-button>
      </template>
      <template #reset_item>
        <vxe-button type="reset" content="重置" @click="resetSearch"></vxe-button>
      </template>

      <!--自定义最近跟进内容插槽-->
      <!-- <template #follower_content="{ column }">
        <div class="follower_content">
          <div class="name">最后跟进内容</div>
          <div class="search-box ml-1">
            <vxe-input
              class="input-box"
              type="search"
              v-model="lastKeyword"
              placeholder="请输入关键字(仅限当前页检索)"
              @change="filterFollowerMethod(column)"
              @search-click="filterFollowerMethod(column)"
              clearable
            ></vxe-input>
          </div>
        </div>
      </template> -->
      <template #self_filter="{ column, $panel }">
        <div class="p-1">
          <input
            type="type"
            v-for="(option, index) in column.filters"
            :key="index"
            v-model="option.data"
            @input="$panel.changeOption($event, !!option.data, option)"
            @keyup.enter="enterFilterEvent({ column, $panel })"
          />
        </div>
      </template>

      <!-- 查看跟进记录 -->
      <template #pager>
        <vxe-pager
          v-if="clueList.length > 0"
          v-model:current-page="tablePage.page"
          v-model:page-size="tablePage.limit"
          :total="tablePage.total"
          :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
          @page-change="handlePageChange"
        >
        </vxe-pager>
      </template>
      <template #download="{ row }">
        <p class="preview"><span class="mr-1" @click="getProjectForm(row, 'download')">下载</span>|<span class="ml-1" @click="getProjectForm(row, 'show')">预览</span></p>
      </template>
      <template #record="{ row }">
        <p class="show-btn" @click="showRowEvent(row, 'record')">查看</p>
      </template>
      <!-- 查看跟进记录 -->
      <!-- 操作 -->
      <template #operate="{ row }">
        <vxe-button size="mini" title="查看详情" @click="transformConfirm(row)">查看详情</vxe-button>
      </template>
    </vxe-grid>
    <!-- <van-action-sheet
      v-model:show="showInvalid"
      :actions="gobackResult"
      @select="onSelectInvalid"
    /> -->

    <vxe-modal v-model="showPool" id="poolModal" width="600" height="400" :show-close="false">
      <template #title>
        <span>{{ isMove ? "请选择转移到的线索池" : "请选择添加到的线索池" }}</span>
      </template>
      <template #default>
        <div class="result px-2">
          <div class="list">
            <div class="item" v-for="(item, index) in salecluePool" :key="index" :class="{ active: clue.pool_id == item.id }" @click="selectPool(item)">
              <span class="mr-1"></span>
              <p>{{ item.name }}</p>
            </div>
          </div>
          <div class="invalid-btn mt-2">
            <vxe-button type="submit" status="primary" @click="saleclueEdit">确认</vxe-button>
            <vxe-button type="reset" @click="saleclueEditCancel">取消</vxe-button>
          </div>
        </div>
      </template>
    </vxe-modal>
    <vxe-modal v-model="showPerson" id="personModal" width="400" height="600" :show-close="false">
      <template #title>
        <span>请选择{{ isFollow ? "负责" : "分配的" }}人</span>
      </template>
      <template #default>
        <div class="department-box">
          <div class="search">
            <van-search v-model="keyword" @search="enterSearch" placeholder="请输入搜索的姓名/手机号" />
          </div>
          <box>
            <div class="breadcrumbs p-2">
              <span v-for="(item, index) in bread" :key="index" @click="backTo(index)">{{ item.name }} <i v-if="index < bread.length - 1"> &gt;</i></span>
            </div>

            <div class="department-content">
              <template v-if="!now">
                <van-cell v-for="(item, index) in department" :key="index" @click="select(item)" :title="item.name" is-link />
              </template>
              <template v-else>
                <van-cell v-for="(item, index) in now.children" :key="index" @click="select(item)" :title="item.name" is-link />
              </template>
              <template v-if="hadUser">
                <div class="my-radio">
                  <div class="list" v-if="now.user_list != 0">
                    <div
                      class="item mb-1"
                      v-for="(item, index) in now.user_list"
                      :key="index"
                      :class="{
                        active: checked == item.id || item.checked || hadValue(item.userid),
                      }"
                      @click="selectPerson(item)"
                    >
                      <span class="mr-1"></span>
                      <p>{{ item.name }}</p>
                    </div>
                  </div>
                  <div class="noData" v-else>
                    <p class="py-3">暂时没有数据</p>
                  </div>
                </div>
              </template>
            </div>
          </box>
          <div class="person-btn my-2">
            <vxe-button type="submit" status="primary" @click="enterSelectPerson">确认</vxe-button>
            <vxe-button type="reset" @click="showPerson = false">取消</vxe-button>
          </div>
        </div>
      </template>
    </vxe-modal>
    <vxe-modal v-model="showRocord" id="recordModal" width="800" height="460" min-width="800" min-height="460" show-zoom resize remember storage transfer>
      <template #title>
        <span>{{ clue.custom_name }}-跟进记录</span>
      </template>
      <template #default>
        <!-- <vxe-textarea
          v-model="comment"
          placeholder="请输入跟进内容"
          :autosize="{ minRows: 4, maxRows: 6 }"
        ></vxe-textarea> -->
        <editcomment :resetEdit="resetEdit" @eidtChange="eidtChange"></editcomment>
        <div class="record-action mt-1">
          <div class="clock-btn mr-1" @click="showClock = true">
            <img src="@img/marketing/clock1.png" alt="" />
            预约
          </div>
          <vxe-button status="primary" @click="clueComment()" title="保存">保存</vxe-button>
        </div>
        <div class="comment-list px-4">
          <div class="list">
            <div class="item py-2" v-for="(item, index) in commentList" :key="index">
              <div class="sec-title">
                <div>
                  <div class="img-box mr-1">
                    <img :src="item.avatar" alt="" />
                  </div>
                  <p>{{ item.username }}</p>
                </div>
                <p>{{ formatDate(item.create_time) }}</p>
              </div>
              <div class="desc mt-1" @click="imageEnlargement" v-html="item.content"></div>
              <div class="delete-btn">
                <p @click="delComment(item.id)">删除</p>
              </div>
            </div>
          </div>
          <vxe-pager
            v-if="commentList.length > 0"
            v-model:current-page="commentPage.page"
            v-model:page-size="commentPage.limit"
            :total="commentPage.total"
            :layouts="['PrevPage', 'Number', 'NextPage', 'Total']"
            @page-change="changeCommentPage"
          >
          </vxe-pager>
        </div>
      </template>
    </vxe-modal>
    <vxe-modal v-model="showClock" id="clickModal" width="400" height="180">
      <template #title>
        <span>请选择预约时间</span>
      </template>
      <template #default>
        <div class="result px-2">
          <div class="time-box mt-2">
            <vxe-input v-model="currentDate" placeholder="日期和时间选择" type="datetime" :disabled-method="disabledClockMethod" transfer></vxe-input>
          </div>

          <div class="invalid-btn mt-2">
            <vxe-button type="submit" status="primary" @click="clueClock">确认</vxe-button>
            <vxe-button type="reset" @click="showClock = false">取消</vxe-button>
          </div>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>
<script>
import Sortable from "sortablejs";
import myTextarea from "./mMyTextarea.vue";
import { mapState } from "vuex";
import XEUtils, { toValueString } from "xe-utils";
import Editcomment from "./editcomment.vue";
import { Skeleton } from "vant";
export default {
  components: {
    myTextarea,
    Editcomment,
  },
  data() {
    return {
      lastKeyword: "",
      // pageSizes: [15, 20, 50, 100],
      resetEdit: false,
      dateFormat: [
        {
          value: "today",
          name: "今天",
        },
        {
          value: "yesterday",
          name: "昨天",
        },
        {
          value: "week",
          name: "本周",
        },
        {
          value: "lastWeek",
          name: "上周",
        },
        {
          value: "month",
          name: "本月",
        },
        {
          value: "lastMonth",
          name: "上月",
        },
        {
          value: "season",
          name: "本季度",
        },
        {
          value: "lastSeason",
          name: "上季度",
        },
        {
          value: "year",
          name: "本年",
        },
        {
          value: "lastYear",
          name: "上年",
        },
      ],
      sortArr: [],
      alloc_pool: JSON.parse(this.$local.get("alloc_pool")),
      isFollow: false,
      sortable2: null,
      currentDate: "", //预约时间
      showClock: false, //显示预约控件
      presetCategory: {}, //预设对象
      checked: 0,
      checkedes: [],
      step: 0,
      keyword: "",
      activeNames: [1],
      once: null,
      second: null,
      third: null,
      person: null,
      department: [],
      hadUser: false,
      now: null,
      bread: [
        {
          id: 0,
          name: "优普道",
        },
      ],
      userList: [],
      department: [],
      searchInfo: {},
      pool_id: null,
      pool_name: "",
      isMove: false,
      showPerson: false,
      loading: false,
      type: 1, //默认1
      showPool: false,
      nowPool: 0,
      salecluePool: [],
      canInsert: true,
      nowType: "",
      reslut: "",
      isbatch: false,
      pageLimit: parseInt(this.$local.get("pageLimit")),
      tablePage: {
        page: 1,
        limit: 15,
        total: 0,
      },
      commentPage: {
        page: 1,
        limit: 6,
        total: 0,
      },

      // 评论
      comment: "",
      //评论列表
      commentList: [],
      //当前操作的对象
      clue: {},

      showRocord: false, //查看更进记录
      clueList: [],
      self_add: [],
      defaultColumn: [
        {
          type: "checkbox",
          width: 40,
          fixed: "left",
        },
        {
          field: "custom_name",
          align: "left",
          title: "客户名称",
          width: 160,
          fixed: "left",
          sortable: false,
          titleHelp: { message: "客户名称必须填写！" },
          // editRender: {
          //   name: "input",
          //   attrs: { placeholder: "请输入客户名称" },
          // },
        },
        {
          field: "mobile",
          title: "联系电话",
          width: 120,
          visible: true,
          sortable: false,
          formatter: this.formatMobile,
          // editRender: {
          //   name: "input",
          //   attrs: { placeholder: "请输入联系电话" },
          // },
        },
        {
          field: "valid_state",
          title: "客户等级",
          width: 120,
          editRender: {
            name: "$select",
            options: [],
            props: { placeholder: "请选择客户等级" },
          },
          filters: [],
          filterMultiple: false,
        },

        {
          field: "clue_time",
          title: "进线时间",
          width: 160,
          visible: true,
          sortable: true,
          // editRender: {
          //   name: "input",
          //   attrs: {
          //     placeholder: "请选择进线时间",
          //     type: "date",
          //     clearable: true,
          //     valueFormat: "yyyy-MM-dd",
          //   },
          // },
          formatter: this.formatClueTime,
        },
        {
          field: "sign_time",
          title: "签单时间",
          width: 160,
          visible: true,
          sortable: true,

          formatter: this.formatFollower,
        },
        {
          field: "platform",
          title: "客户来源",
          width: 140,
          visible: true,
          sortable: false,
          filters: [],
          filterMultiple: false,
          // editRender: {
          //   name: "$select",
          //   options: [],
          // },
          formatter: this.formatPlatform,
        },

        {
          field: "company_name",
          title: "公司名称",
          width: 160,
          sortable: false,
          editRender: {
            name: "input",
            attrs: { placeholder: "请输入公司名称" },
          },
          filters: [{ data: "" }],
          slots: {
            filter: "self_filter",
          },
        },
        {
          field: "customer_dep",
          title: "部门",
          width: 160,
          visible: false,
          sortable: false,
          editRender: {
            name: "input",
            attrs: { placeholder: "请输入联系人所在部门" },
          },
          filters: [{ data: "" }],
          slots: {
            filter: "self_filter",
          },
        },
        {
          field: "customer_position",
          title: "岗位",
          width: 160,
          visible: false,
          sortable: false,
          editRender: {
            name: "input",
            attrs: { placeholder: "请输入联系人职位" },
          },
          filters: [{ data: "" }],
          slots: {
            filter: "self_filter",
          },
        },
        {
          field: "wechat_sn",
          title: "微信/电话2",
          width: 160,
          visible: false,
          sortable: false,
          editRender: {
            name: "input",
            attrs: { placeholder: "请输入联系人微信/电话2" },
          },
          filters: [{ data: "" }],
          slots: {
            filter: "self_filter",
          },
        },
        {
          field: "qualify_type",
          title: "资质类型",
          width: 160,
          sortable: false,
          filters: [],
          filterMultiple: false,
          formatter: this.formatClueKind,
        },
        {
          field: "qualify_kind",
          title: "资质名称",
          width: 120,
          visible: true,
          sortable: false,
          filters: [{ data: "" }],
          slots: {
            filter: "self_filter",
          },
        },
        {
          field: "contract_amount",
          title: "合同金额",
          width: 120,
          visible: true,
          sortable: false,
        },

        {
          field: "follower_temp",
          title: "负责人",
          width: 120,
          visible: false,
          sortable: false,
        },
        {
          field: "pool_id_temp",
          title: "线索池",
          width: 120,
          visible: false,
          sortable: false,
        },
        {
          field: "wf_state",
          title: "审批状态",
          width: 120,
          visible: false,
          sortable: false,
          formatter: this.formatterWfState,
        },
        {
          field: "download",
          title: "下载/预览",
          width: 140,
          visible: false,
          slots: { default: "download" },
        },
        {
          field: "record",
          title: "跟进记录",
          width: 80,
          fixed: "right",
          slots: { default: "record" },
        },

        {
          field: "operate",
          colId: "operate",
          title: "操作",
          width: 100,
          fixed: "right",
          slots: { default: "operate" },
        },
      ],
      gridOptions: {
        cellClassName: "my-cell",
        border: "full",
        columnKey: true,
        class: "sortable-column-demo",
        cellClassName: "cell-box",
        size: "medium",
        align: "center",
        resizable: true,
        showHeaderOverflow: true,
        showOverflow: true,
        highlightHoverRow: true,
        keepSource: true,
        stripe: true,
        round: true,
        id: "full_edit_1",
        height: 800,
        rowId: "order_id",
        scrollX: {
          enabled: false,
        },

        customConfig: {
          storage: true,
          checkMethod: this.checkColumnMethod,
        },
        formConfig: {
          titleWidth: 100,
          titleAlign: "right",
          items: [
            {
              itemRender: {},
              slots: { default: "search_item" },
            },

            { itemRender: {}, slots: { default: "submit_item" } },
            { itemRender: {}, slots: { default: "reset_item" } },
          ],
        },

        toolbarConfig: {
          className: "myTool",
          buttons: [
            // { code: "myInsert", name: "新增", icon: "fa fa-plus" },
            {
              code: "myRefresh",
              name: "刷新",
            },
          ],

          refresh: false, //刷新
          import: false, //导入
          export: false, //导出
          print: false, //打印
          zoom: true, //放大
          custom: true, //自定义
        },
        data: [],
        columns: [],
        rowConfig: {
          isCurrent: true,
        },
        filterConfig: {
          remote: true,
        },
        keyboardConfig: {
          isArrow: true,
        },
        checkboxConfig: {
          reserve: true,
          highlight: true,
          range: true,
        },
        editRules: {
          // custom_name: [{ required: true, message: "请输入名称" }],
          mobile: [
            { required: true, message: "联系电话必须填写" },
            { min: 11, max: 11, message: "请输入长度为11位的电话" },
          ],
          clue_kind: [{ required: true, message: "请选择线索类型" }],
          platform: [{ required: true, message: "请选择线索来源" }],
        },
        editConfig: {
          trigger: "dblclick",
          mode: "row",
          showStatus: true,
        },
      },
    };
  },
  created() {
    const CUSTOMER_TABLE = this.$local.get("CUSTOMER_TABLE") ? JSON.parse(this.$local.get("CUSTOMER_TABLE")) : "";
    if (CUSTOMER_TABLE) {
      let curArr = [];
      CUSTOMER_TABLE.find((item) => {
        curArr.push(item.field);
      });
      let newArr = [];
      this.defaultColumn.sort((a, b) => {
        const prev = curArr.indexOf(a.field);
        const next = curArr.indexOf(b.field);
        return prev - next;
      });
      this.defaultColumn.forEach((item) => {
        newArr.push(item);
      });
      this.defaultColumn = newArr;
    }
    this.gridOptions.columns = this.defaultColumn;
    if (!this.pageLimit) {
      this.$local.set("pageLimit", 15);
      this.pageLimit = 15;
    }
    this.getPresetCategory();
    // this.getClueList();
    this.getSalecluePool(2);
  },
  mounted() {
    setTimeout(() => {
      this.getDom();
      this.dealConst();
      this.columnDrop2();
    }, 1000);
  },
  props: {
    source: {
      type: String,
      default: "customer", //默认线索池
    },
  },

  computed: {
    ...mapState({
      cluePlatform: (state) => {
        return [...state.constant.sale_platform];
      },
      transferPool: (state) => {
        return [...state.clueInfo];
      },
      talentType: (state) => {
        return [...state.constant.talent_type];
      },
      clueType: (state) => {
        return [...state.constant.clue_type];
      },
      validState: (state) => {
        return [...state.constant.valid_state];
      },
    }),
    formatDate() {
      let _this = this;
      return function (val) {
        return _this.$utils.formatDate(val, "yyyy-MM-dd hh:mm");
      };
    },
  },
  watch: {
    source: {
      handler(newVal, oldVal) {
        this.type = newVal;
        this.getDom();
        this.dealConst();
      },
      immediate: true,
    },
  },
  methods: {
    eidtChange(e) {
      this.comment = e;
    },
    clearFollower() {
      this.searchInfo.follower = "";
      this.getClueList();
    },
    focusEvent() {
      let arr = [];
      this.salecluePool.forEach((item) => {
        if (item.id) arr.push(item.id);
      });
      this.isFollow = true;
      this.showPerson = true;
      this.getUserList(arr.toString());
    },
    keyupEvent() {},
    changeTableColumn(item, newIndex, oldIndex) {
      const $table = this.$refs.xGrid;
      const { fullColumn, tableColumn } = $table.getTableColumn();
      const targetThElem = item;
      const wrapperElem = targetThElem.parentNode;
      const newColumn = fullColumn[newIndex];
      if (newColumn.fixed) {
        // 错误的移动
        if (newIndex > oldIndex) {
          wrapperElem.insertBefore(targetThElem, wrapperElem.children[oldIndex]);
        } else {
          wrapperElem.insertBefore(wrapperElem.children[oldIndex], targetThElem);
        }
        return this.$message.error("固定列不允许移动");
      }
      // 转换真实索引
      const oldColumnIndex = $table.getColumnIndex(tableColumn[oldIndex]);
      const newColumnIndex = $table.getColumnIndex(tableColumn[newIndex]);
      // 移动到目标列
      const currRow = fullColumn.splice(oldColumnIndex, 1)[0];
      fullColumn.splice(newColumnIndex, 0, currRow);
      $table.loadColumn(fullColumn);
      // 缓存表头
      const currColumn = this.defaultColumn.splice(oldColumnIndex, 1)[0];
      this.defaultColumn.splice(newColumnIndex, 0, currColumn);
      this.$local.set("CUSTOMER_TABLE", JSON.stringify(this.defaultColumn));
    },
    columnDrop2() {
      this.$nextTick(() => {
        const $table = this.$refs.xGrid;
        this.sortable2 = Sortable.create($table.$el.querySelector(".body--wrapper>.vxe-table--header .vxe-header--row"), {
          handle: ".vxe-header--column:not(.col--fixed)",
          onEnd: ({ item, newIndex, oldIndex }) => {
            this.changeTableColumn(item, newIndex, oldIndex);
          },
        });
      });
    },
    getDom() {
      const $grid = this.$refs.xGrid;
      if ($grid) {
        this.searchInfo = {};
        $grid.clearFilter();
        $grid.clearCheckboxRow();
        $grid.clearCheckboxReserve();

        this.refreshColumn();
        this.tablePage.page = 1;
        this.tablePage.limit = this.pageLimit;
        this.tablePage.total = 0;
        this.getClueList();
      }
    },

    sortMethod(val) {
      if (!Boolean(val.order)) {
        this.sortArr = [];
      } else {
        let arr = [[val.property, val.order]];
        this.sortArr = arr;
      }
      this.getClueList();
    },
    filterFollowerMethod(column) {
      let data = this.clueList.filter((item) => {
        return item.follower_content.indexOf(this.lastKeyword) != -1;
      });
      this.gridOptions.data = data;
    },
    inputFilter(key, value) {
      this.searchInfo[key] = value;
      this.getClueList();
    },
    enterFilterEvent({ column, $panel }) {
      let skey = column.property;
      let svalue = column.filters[0].data.toString();
      this.inputFilter(skey, svalue);
    },
    filterMethod(val) {
      let skey = val.property;
      let svalue = val.values.toString();
      if (
        skey == "site_chinese" ||
        skey == "company_name" ||
        skey == "customer_dep" ||
        skey == "customer_position" ||
        skey == "wechat_sn" ||
        skey == "qualify_kind" ||
        skey == "feedback" ||
        skey == "remarks"
      ) {
        let inputValue = val.datas.toString();
        return this.inputFilter(skey, inputValue);
      }

      switch (skey) {
        case "valid_state":
          this.searchInfo.validState = svalue;
          this.getClueList();
          break;
        case "qualify_type":
          this.searchInfo.qualify_type = svalue;
          this.getClueList();
          break;
        case "clue_type":
          this.searchInfo.menu = svalue;
          this.getClueList();
          break;
        case "platform":
          this.searchInfo.platform = svalue;
          this.getClueList();
          break;
          // case "pool_id ":
          //   this.searchInfo.poolId = svalue;
          //   this.getClueList();
          break;

        default:
          break;
      }
    },
    cellStyle({ row, rowIndex, column }) {
      if (column.property === "valid_state") {
        if (row.valid_state == 10) {
          return {
            color: "#ff5757",
          };
        } else if (row.valid_state == 20) {
          return {
            color: "#04d1af",
          };
        } else if (row.valid_state == 30) {
          return {
            color: "#6043dd",
          };
        } else {
          return {
            color: "#999999",
          };
        }
      }
    },
    clueClock() {
      this.showClock = false;
      let day = this.currentDate;
      // 客户
      this.$api
        .customerAppoints({
          csutomer_id: this.clue.id,
          time: day,
          source: "customer",
        })
        .then((res) => {
          if (res.code == 200) {
            this.$XModal.message({
              content: "您已成功预约于" + day + "回访客户",
              status: "success",
            });
          }
        });
    },
    // 获取预设对象
    getPresetCategory() {
      // let data = ["qualify_kind", "clue_kind"];
      this.$api.getPresetCategory({ uniqueKey: "clue_kind" }).then((res) => {
        this.presetCategory = res.data;
      });
    },
    disabledClockMethod({ date }) {
      return new Date().getTime() >= date.getTime();
    },
    disabledDateMethod({ date }) {
      return new Date().getTime() < date.getTime();
    },
    findUser(arr) {
      arr.forEach((item) => {
        if (item.children) this.findUser(item.children);
        if (item.user_list) {
          this.userList.push(...item.user_list);
        }
      });
    },
    enterSearch() {
      this.userList = [];
      this.findUser(this.department);
      this.userList = this.userList.filter((val) => {
        return val.name.indexOf(this.keyword) !== -1 || val.userid.toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1;
      });
      this.hadUser = true;
      this.now = {};
      this.now.user_list = this.userList;
      this.bread = [
        {
          id: 0,
          name: "优普道",
        },
        {
          id: 1,
          name: "搜索结果",
        },
      ];
    },
    hadValue(userid) {
      let c = this.checkedes.findIndex((val) => {
        return val.userid == userid;
      });
      return c !== -1;
    },
    selectPerson(item) {
      this.checked = item.id;
      this.person = item;
    },
    backTo(index) {
      if (index == this.bread.length - 1) return;
      if (index == 0) {
        this.now = null;
        this.hadUser = false;
      } else {
        this.now = this.bread[index];
        this.hadUser = !this.bread[index].user_list ? true : false;
      }
      this.bread.splice(index + 1, this.bread.length);
    },
    select(item) {
      if (!item.user_list) {
        this.hadUser = true;
        this.hadUser;
      }
      this.now = item;
      this.bread.push(item);
      return;
    },
    getUserList(pool_id) {
      this.$api
        .getUserList({
          reqType: "pool",
          stockId: pool_id ? pool_id : this.pool_id,
        })
        .then((res) => {
          this.department = res.data;
        });
    },
    resetSearch() {
      this.pool_id = null;
      this.searchInfo = {};
      this.getClueList();
    },
    clickSearch() {
      this.tablePage.page = 1;
      this.tablePage.limit = this.pageLimit;
      this.tablePage.total = 0;
      this.getClueList();
    },
    refreshColumn() {
      let column;
      const $grid = this.$refs.xGrid;
      $grid.refreshColumn(column);
    },
    // 线索池
    getSalecluePool(val) {
      // 0/1/2
      // 1全部 2
      this.$api.getSalecluePool({ isAll: val }).then((res) => {
        this.salecluePool = res.data;
        // if (this.salecluePool.length > 0) {
        //   this.clue.pool_id = this.salecluePool[0].id;
        //   this.clue.pool_id_temp = this.salecluePool[0].name;
        // }
      });
    },

    // 点击工具框
    toolbarButtonClickEvent({ code }) {
      const $grid = this.$refs.xGrid;
      switch (code) {
        case "myInsert":
          this.$router.push("mClueSign");

          break;
        case "mySave":
          // 新增、移除、更新
          const { insertRecords, removeRecords, updateRecords } = $grid.getRecordset();
          if (insertRecords.length == 0) return;
          let clue = insertRecords[0];
          // if (!insertRecords.pool_id) return this.$notify("请选择线索池");
          if (!clue.platform)
            return this.$XModal.message({
              content: `请选择线索来源`,
              status: "error",
            });
          if (!clue.clue_kind)
            return this.$XModal.message({
              content: `请选择线索类型`,
              status: "error",
            });
          if (!clue.custom_name)
            return this.$XModal.message({
              content: `请输入客户名称`,
              status: "error",
            });
          if (!clue.mobile)
            return this.$XModal.message({
              content: `请输入联系电话`,
              status: "error",
            });
          if (!/^1[3456789]\d{9}$/.test(clue.mobile))
            return this.$XModal.message({
              content: `请正确填写手机号`,
              status: "error",
            });
          delete clue.id;
          this.getSalecluePool();
          this.clue = clue;
          this.showPool = true;
          break;
        case "myRefresh":
          this.canInsert = true;
          this.getClueList();
          break;
      }
    },

    selectPool(item) {
      this.clue.pool_id = item.id;
      this.pool_name = item.name;

      // if (!this.isMove) {
      //   this.clue.pool_id = item.id;
      // } else {
      //   this.pool_id = item.id;
      //   this.pool_name = item.name;
      // }
    },

    clearCheckBox() {
      const $table = this.$refs.xGrid;
      $table.clearCheckboxRow();
      $table.clearCheckboxReserve();
    },
    onSelectInvalid() {
      const $table = this.$refs.xGrid;
      let data;
      switch (this.nowType) {
        case "goback":
          if (!this.isBatch) {
            data = {
              clue_id: this.clue.id,
              content: this.reslut,
            };
          } else {
            let records = [];
            let otherRecords = $table.getCheckboxReserveRecords();
            let nowRecords = $table.getCheckboxRecords();
            records = [...otherRecords, ...nowRecords];
            let ids = [];
            records.forEach((item) => {
              ids.push(item.id);
            });
            data = {
              clue_id: ids.toString(),
              content: this.reslut,
            };
          }
          this.$api.saleclueGoback(data).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                content: `操作成功!`,
                status: "success",
              });
              this.showInvalid = false;
              this.clearCheckBox();
              this.isBatch = false;
              // 需要刷新
              this.getClueList();
            }
          });
          break;
        case "abandon":
          if (!this.isBatch) {
            data = {
              clue_id: this.clue.id,
              content: this.reslut,
            };
          } else {
            let records = [];
            let otherRecords = $table.getCheckboxReserveRecords();
            let nowRecords = $table.getCheckboxRecords();
            records = [...otherRecords, ...nowRecords];
            let ids = [];
            records.forEach((item) => {
              ids.push(item.id);
            });
            data = {
              clue_id: ids.toString(),
              content: this.reslut,
            };
          }
          this.$dialog
            .confirm({
              message: "确认废弃该线索",
            })
            .then(() => {
              this.$api.saleclueAbandon(data).then((res) => {
                if (res.code == 200) {
                  this.isMove = false;
                  this.pool_id = null;
                  this.pool_name = "";
                  this.showInvalid = false;
                  this.clearCheckBox();
                  this.isBatch = false;
                  this.getClueList();
                  this.$XModal.message({
                    content: `操作成功!`,
                    status: "success",
                  });
                }
              });
            })
            .catch(() => {});
          break;

        default:
          break;
      }
    },
    changeCommentPage(e) {
      this.commentPage.page = e.currentPage;
      this.getCommentList();
    },
    handlePageChange(e) {
      this.lastKeyword = "";
      this.tablePage.page = e.currentPage;
      this.pageLimit = e.pageSize;
      this.$local.set("pageLimit", e.pageSize);
      this.getClueList();
    },
    delComment(id) {
      this.$api.mDelComment({ id: id }).then((res) => {
        if (res.code == 200) {
          let index = this.commentList.findIndex((val) => {
            return val.id == id;
          });
          this.commentList.splice(index, 1);
          this.$XModal.message({
            content: `删除成功!`,
            status: "success",
          });
        }
      });
    },
    // 评论列表
    getCommentList() {
      let data = {
        id: this.clue.clue_id,
        customer_id: this.clue.id,
        limit: this.commentPage.limit,
        page: this.commentPage.page,
      };
      this.$api.getMCommentlist(data).then((res) => {
        this.commentList = res.data.list;
        this.commentPage.total = res.data.count;
        this.loading = false;
        if (this.commentList.length >= this.total) {
          this.finished = true;
        }
      });
    },
    // 评论
    clueComment() {
      if (!this.comment)
        return this.$XModal.message({
          content: `跟进内容不能为空`,
          status: "error",
        });
      this.resetEdit = true;

      this.$api
        .addCustomerComment({
          content: this.comment,
          clue_id: this.clue.id,
          customer_id: this.clue.id,
          touchUrl: window.location.href,
        })
        .then((res) => {
          if (res.code == 200) {
            this.resetEdit = false;
            this.commentPage.page = 1;
            this.commentList = [];
            this.comment = "";
            this.getCommentList();
            this.getClueList();
            this.$XModal.message({
              content: `发布成功!`,
              status: "success",
            });
          }
        });
    },
    // 查看跟进记录
    showRowEvent(row, type) {
      this.clue = row;
      this.nowType = type;

      switch (type) {
        case "record":
          this.showRocord = true;
          this.getCommentList();
          break;
        default:
          break;
      }
    },
    getProjectForm(row, key) {
      this.$api.getProjectForm({ id: row.order_id }).then((res) => {
        this.$local.remove("isUpdate");
        switch (key) {
          case "show":
            let path = res.data.file_path;
            if (/^.+(\.pdf|\.jpg|\.jpeg|\.png)$/.test(path)) {
              var link = document.createElement("a");
              link.href = path;
              link.target = "_blank";
              link.click();
              setTimeout(() => {
                link.remove();
              }, 1000);
              return;
            } else if (/^.+(\.mp4)$/.test(path)) {
              let url = encodeURIComponent(path);
              return this.$router.push({
                path: "/preview",
                query: {
                  url: url,
                },
              });
            } else {
              let url = encodeURIComponent(path);
              let officeUrl = "http://view.officeapps.live.com/op/view.aspx?src=" + url;
              // window.open(officeUrl, "_target");
              var link = document.createElement("a");
              link.href = officeUrl;
              link.target = "_target";
              link.click();
              setTimeout(() => {
                link.remove();
              }, 1000);
            }
            break;
          case "download":
            // window.open(res.data.file_path, "_target");
            var link = document.createElement("a");
            link.href = res.data.file_path;
            link.target = "_target";
            link.click();
            setTimeout(() => {
              link.remove();
            }, 1000);
            break;
          default:
            break;
        }
      });
    },
    saleclueShareClue(data) {
      this.$api.saleclueShareClue(data).then((res) => {
        if ((res.code = 200)) {
          this.person = null;
          this.checked = "";
          this.showPerson = false;
          this.clearCheckBox();
          this.isBatch = false;
          this.$XModal.message({
            content: `分配成功!`,
            status: "success",
          });
          this.getClueList();
        }
      });
    },
    enterFollower() {
      this.searchInfo.follower = this.person.userid;
      this.searchInfo.follower_temp = this.person.name;
      this.isFollow = false;
      this.getClueList();
    },

    // 分配选人
    enterSelectPerson() {
      this.showPerson = false;
      if (this.isFollow) return this.enterFollower();
      if (this.isBatch) return this.batchShare();
      let data = {
        id: this.clue.id,
        pool_id: this.clue.pool_id,
        follower: this.person.userid,
      };
      if (!this.clue.follower) {
        this.saleclueShareClue(data);
      } else {
        this.$dialog
          .confirm({
            message: "已分配负责人，确认重新分配给" + this.person.name,
          })
          .then(() => {
            this.saleclueShareClue(data);
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    transformConfirm(row) {
      this.clue = row;
      this.$router.push({
        path: "customerDetail",
        query: {
          id: row.id,
        },
      });
    },
    saveRowEvent(row) {},
    editRowEvent(row) {
      const $grid = this.$refs.xGrid;
      if ($grid) {
        $grid.setActiveRow(row);
      }
    },
    editClosedEvent({ row, column }) {
      const $table = this.$refs.xGrid;
      const field = column.property;
      const cellValue = row[field];
      let data = {
        id: "",
        custom_name: "",
        platform: "",
        valid_state: "",
        contacts: "",
        mobile: "",
        wechat_sn: "",
        company_name: "",
        customer_dep: "",
        customer_position: "",
        clue_time: "",
      };
      [...Object.keys(data)].forEach((val) => {
        data[val] = row[val];
      });
      if ($table.isUpdateByRow(row, field)) {
        this.$api.eidtCustomer(data).then((res) => {
          if (res.code == 200) {
            this.$XModal.message({
              content: `修改成功!`,
              status: "success",
            });
            $table.reloadRow(row, null, field);
          }
        });
      }
    },
    convertKey(arr, type) {
      arr.forEach((item, index) => {
        item.label = item.name;
        item.value = item.id;
      });
      return arr;
    },
    // 获取线索数据
    getClueList() {
      this.loading = true;
      const $table = this.$refs.xGrid;
      let data;
      data = {
        limit: this.tablePage.limit,
        page: this.tablePage.page,
      };
      if (this.sortArr.length != 0) {
        data.sort = JSON.stringify(this.sortArr);
      }
      if (this.searchInfo) {
        data = { ...data, ...this.searchInfo };
      }
      if (data.clue_time) data.clue_time = data.clue_time.toString();
      if (data.sign_time) data.sign_time = data.sign_time.toString();
      data.poolId = this.pool_id;
      this.$api.getCustomer(data).then((res) => {
        this.clueList = res.data.list;
        this.tablePage.total = res.data.count;
        this.gridOptions.data = this.clueList;
        this.loading = false;
      });
    },

    dealConst() {
      this.self_add = [];
      this.cluePlatform.forEach((item) => {
        if (item.id == 10 || item.id == 80 || item.id == 90 || item.id == 61) {
          this.self_add.push(item);
        }
      });
      const $grid = this.$refs.xGrid;
      if ($grid) {
        // 等级
        let oValid = this.convertKey(this.validState);
        const oValidColumn = $grid.getColumnByField("valid_state");
        oValidColumn.editRender.options = oValid;
        oValidColumn.filters = oValid;

        let oClueKind = this.convertKey(this.presetCategory.clue_kind);
        const oClueKindColumn = $grid.getColumnByField("qualify_type");
        // oClueKindColumn.editRender.options = oClueKind;
        oClueKindColumn.filters = oClueKind;

        let oPlatform = this.convertKey(this.cluePlatform);
        let oSelfAdd = this.convertKey(this.self_add);
        const oPlatformColumn = $grid.getColumnByField("platform");
        // oPlatformColumn.editRender.options = oSelfAdd;//不可修改客户来源
        oPlatformColumn.filters = oPlatform;

        // let oClueType = this.convertKey(this.clueType, "clueType");
        // const oClueTypeColumn = $grid.getColumnByField("clue_type");
        // oClueTypeColumn.filters = oClueType;
      }
    },

    formatterClueType({ cellValue }) {
      const item = this.clueType.find((item) => item.id === cellValue);
      return item ? item.name : "";
    },
    formatMobile({ row, cellValue }) {
      return this.type == 3 && !row.show_tel ? cellValue.replace(/(\d{3})\d*(\d{4})/, "$1****$2") : cellValue;
    },

    formatterPool({ cellValue }) {
      const item = this.salecluePool.find((item) => item.id === cellValue);
      return item ? item.name : "";
    },
    // 审批状态
    formatterWfState({ cellValue }) {
      return cellValue == 1 ? "审批中" : cellValue == 2 ? "已办结" : cellValue == 3 ? "已驳回" : "/";
    },
    formatClueTime({ cellValue }) {
      return cellValue ? XEUtils.toDateString(cellValue, "yyyy-MM-dd") : "";
    },
    formatFollower({ cellValue }) {
      return cellValue ? XEUtils.toDateString(new Date(cellValue * 1000), "yyyy-MM-dd") : "";
    },

    formatClueKind({ cellValue }) {
      const item = this.presetCategory.clue_kind.find((item) => item.id === cellValue);
      return item ? item.name : "";
    },
    formatPlatform({ cellValue }) {
      const item = this.cluePlatform.find((item) => item.id === cellValue);
      return item ? item.name : "";
    },
    checkColumnMethod({ column }) {
      if (["custom_name", "valid_state", "mobile", "platform"].includes(column.property)) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.ig-table {
  .show-btn {
    color: #4892fe;
    cursor: pointer;
  }
  .preview {
    color: #538ff8;
    & > span {
      cursor: pointer;
    }
  }
  &:deep(.vxe-form--wrapper) {
    margin: 20px 0;
    @include flex-center();
    .input-box {
      width: 300px;
    }
  }
  .search-box {
    @include flex-center();
  }
  &:deep(.myTool) {
    .vxe-tools--wrapper {
      margin-right: 10px;
    }
  }
  &:deep(.flex-box) {
    .vxe-cell--html {
      @include flex-start();
    }
  }
  &:deep(.cell-box) {
    color: #333;
  }
  &:deep(.el-range-editor.el-input__inner) {
    height: 32px;
    width: 240px;
  }
  .follower_content {
    @include flex-start();
    align-items: center;
    .search-box {
      .input-box {
        width: 200px;
      }
    }
  }
}
.record-action {
  @include flex-start();
  justify-content: flex-end;
  .clock-btn {
    cursor: pointer;
    width: 100px;
    height: 40px;
    border: 1px solid #f27800;
    border-radius: 25px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #f37f0d;
    line-height: 40px;
    text-align: center;
    & > img {
      width: 20px;
      height: 20px;
      display: inline-block;
      margin-top: 4px;
    }
  }
}
.comment-list {
  .list {
    .item {
      .sec-title {
        @include flex-between();

        & > p {
          font-size: 20px;
          color: #a4a4a4;
        }
        & > div {
          .img-box {
            width: 40px;
            height: 40px;
            background: #f7f8fa;
            overflow: hidden;
            border-radius: 50%;
            & > img {
              width: 100%;
            }
          }
          @include flex-between();
          font-size: 20px;
          color: #909090;
          span {
            color: #4892fe;
          }
        }
      }
      .desc {
        font-size: 18px;
        color: #909090;
        line-height: 28px;
      }
      .delete-btn {
        @include flex-end();
        & > p {
          font-size: 18px;
          color: #f34343;
        }
      }
    }
    .item:not(:last-child) {
      border-bottom: 1px solid#F7F8FA;
    }
  }
}
.result {
  background: #ffffff;
  border-radius: 20px;
  .time-box {
    @include flex-center();
  }
  .list {
    .item {
      @include flex-start();
      align-items: center;
      font-size: 18px;
      color: #909090;
      cursor: pointer;
      padding: 10px 20px;
      & > span {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: #f7f8fa;
        border: 1px solid #d5d5d5;
        border-radius: 50%;
      }
      & > p {
        flex: 1;
      }
    }
    .active {
      background: #e9f2ff;
      border-radius: 10px;
      color: #4892fe;
      & > span {
        border: 3px solid #4892fe;
        position: relative;
        &::after {
          content: "";
          width: 10px;
          height: 10px;
          background: #4892fe;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .invalid-btn {
    @include flex-center();
  }
}
.department-box {
  position: relative;
  .search {
    width: 100%;
    margin: 0 auto;
  }
  &:deep(.van-radio__label) {
    font-size: 20px;
    color: #808080;
    line-height: normal;
  }
  .breadcrumbs {
    font-size: 16px;
    color: #aaaaaa;
  }
  .department-content {
    min-height: 340px;
  }
  .my-radio {
    .list {
      .item {
        border-radius: 10px;
        background: #ffffff;
        @include flex-start();
        align-items: center;
        font-size: 14px;
        color: #909090;
        cursor: pointer;
        padding: 4px 10px;
        & > span {
          display: inline-block;
          width: 16px;
          height: 16px;
          background: #f7f8fa;
          border: 1px solid #d5d5d5;
          border-radius: 50%;
        }
        & > p {
          flex: 1;
        }
      }
      .active {
        background: #e9f2ff;
        border-radius: 10px;
        color: #4892fe;
        & > span {
          border: 3px solid #4892fe;
          position: relative;
          &::after {
            content: "";
            width: 8px;
            height: 8px;
            background: #4892fe;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    .noData {
      font-size: $contentFontSize;
      color: #aaaaaa;
      text-align: center;
    }
  }
  .person-btn {
    @include flex-center();
  }
}
</style>
