<template>
  <div class="ig-census py-2">
    <div class="effective">
      <div class="content">
        <div class="shadow">
          <div class="top-box px-4 py-2">
            <div class="sec-title">进线分析</div>
            <div class="filter-box">
              <div class="label">
                <span>分公司：</span>
                <el-select v-model="companyID" placeholder="请选择分公司" @change="changeCompany">
                  <el-option v-for="item in salecluePool" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </div>
              <div class="label">
                <span>日期：</span>
                <div class="time-box">
                  <el-radio-group v-model="search.type" @change="changeType">
                    <el-radio-button label="day">日</el-radio-button>
                    <el-radio-button label="month">月</el-radio-button>
                  </el-radio-group>
                  <el-date-picker
                    class="ml-1"
                    v-model="rangeTime"
                    :type="search.type == 'day' ? 'daterange' : 'monthrange'"
                    :format="search.type == 'day' ? 'YYYY-MM-DD' : 'YYYY-MM'"
                    value-format="YYYY-MM-DD"
                    start-placeholder="选择时间"
                    end-placeholder="选择时间"
                    @change="selectTime"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="deal-box">
            <div id="pie-box" class="pie-box"></div>
            <div class="table-box px-2 py-4">
              <el-table :data="censusData" border style="width: 100%">
                <el-table-column prop="name" label="平台" align="center" />
                <el-table-column prop="validity_status_v" label="有效" align="center" />
                <el-table-column prop="validity_status_p" label="待定" align="center" />
                <el-table-column prop="validity_status_i" label="无效" align="center" />
                <el-table-column prop="validity_status_c" label="总数" align="center" />
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="effective mt-2">
      <div class="content">
        <div class="shadow">
          <div class="top-box px-4 py-2">
            <div class="sec-title">投入产出比分析</div>
          </div>
          <div class="deal-box">
            <div class="px-2 py-4">
              <div id="echart-box" class="chart-box"></div>
            </div>
            <div class="table-box px-2 py-4">
              <el-table :data="roiData" border style="width: 100%">
                <el-table-column prop="platform_temp" label="平台" align="center" />
                <el-table-column prop="trading_amount" label="金额" align="center" />
                <el-table-column prop="consume" label="消费" align="center" />
                <el-table-column prop="roi" label="ROI" align="center" />
              </el-table>
            </div>
            <!-- <template v-else>
              <div class="noData">
                <el-empty></el-empty>
              </div>
            </template> -->
          </div>
        </div>
      </div>
    </div>
    <report></report>
  </div>
</template>

<script>
import * as echarts from "echarts";
import report from "./report/report.vue";
let myEchart = null;
let myPie = null;
export default {
  components: { report },
  data() {
    return {
      platform: "",
      censusData: [],
      roiData: [],
      salecluePool: [],
      search: {
        type: "day",
      },
      xAxis: [],
      seriesC: [],
      seriesT: [],
      seriesR: [],
      rangeTime: [],
      companyID: "",
    };
  },
  created() {
    // this.getSalecluePool();
    // this.getTime().then(() => {
    this.getCompany();
    this.saleclueCensus();
    // });
  },

  methods: {
    changeType() {
      delete this.search.startTime;
      delete this.search.endTime;
      this.rangeTime = [];
      this.saleclueCensus();
    },
    getTime() {
      return new Promise((resolve, reject) => {
        //获取分类
        let end = this.$utils.formatDate(new Date().getTime() / 1000, "yyyy-MM-dd");
        let s = new Date();
        s.setDate(s.getDate() - 7);
        let start = this.$utils.formatDate(s.getTime() / 1000, "yyyy-MM-dd");
        this.rangeTime = [start, end];
        this.search.startTime = start;
        this.search.endTime = end;
        resolve();
      });
    },
    changeCompany() {
      let item = this.salecluePool.find((val) => {
        return val.id == this.companyID;
      });
      this.search.poolId = item.pool_id;
      this.saleclueCensus();
    },
    getCompany() {
      this.$api
        .getCompany()
        .then((res) => {
          res.data.unshift({
            id: "",
            name: "全部",
          });
          this.salecluePool = res.data;
        })
        .catch((err) => {});
    },
    // 线索池
    getSalecluePool(val) {
      // 0/1/2
      // 1全部 2
      this.$api.getSalecluePool({ isAll: val }).then((res) => {
        this.salecluePool = res.data;
      });
    },
    selectTime(e) {
      if (e) {
        this.search.startTime = e[0];
        this.search.endTime = e[1];
        this.saleclueCensus();
      }
    },
    saleclueCensus() {
      this.$api
        .saleclueCensus(this.search)
        .then((res) => {
          this.censusData = res.data;
          let arr = [];
          this.censusData.forEach((item, index) => {
            if (index !== this.censusData.length - 1)
              arr.push({
                name: item.name,
                value: item.validity_status_c,
                chiild: [
                  { name: "有效", value: item.validity_status_v },
                  { name: "无效", value: item.validity_status_i },
                  { name: "待定", value: item.validity_status_p },
                ],
              });
          });
          this.seriesData = arr;
          if (!myPie) {
            this.$nextTick(() => {
              this.initPie();
            });
          } else {
            this.platform = "";
            myPie.setOption({
              title: {
                text: this.platform,
              },
              series: [
                {
                  data: this.seriesData,
                },
              ],
            });
          }
        })
        .catch((err) => {});

      // 投产比
      this.$api
        .roiratio(this.search)
        .then((res) => {
          this.roiData = res.data;
          this.xAxis = [];
          this.seriesC = [];
          this.seriesT = [];
          this.seriesR = [];
          this.roiData.forEach((item, index) => {
            this.xAxis.push(item.platform_temp);
            this.seriesC.push(item.consume);
            this.seriesT.push(item.trading_amount);
            this.seriesR.push(item.roi);
          });
          if (!myEchart) {
            this.$nextTick(() => {
              this.initEchart();
            });
          } else {
            this.platform = "";
            myEchart.setOption({
              xAxis: {
                axisLabel: {
                  interval: 0,
                  rotate: 40,
                },
                data: [...this.xAxis],
              },
              series: [
                {
                  name: "消费",
                  type: "bar",
                  data: [...this.seriesC],
                  smooth: true,
                },
                {
                  name: "成交金额",
                  type: "bar",
                  data: [...this.seriesT],
                  smooth: true,
                },
                {
                  name: "ROI",
                  type: "line",
                  data: [...this.seriesR],
                  smooth: true,
                },
              ],
            });
          }
        })
        .catch((err) => {});
    },
    initEchart() {
      myEchart = echarts.init(document.getElementById("echart-box"));
      myEchart.setOption({
        color: ["#5087EC", "#68BBC4", "#58A55C", "#F2BD42", "#A2C242"],
        barGap: 0,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#ffffff",
            },
          },
        },
        legend: {
          x: "center",
          y: "top",
        },

        xAxis: {
          axisLabel: {
            interval: 0,
          },
          data: [...this.xAxis],
        },
        yAxis: [
          {
            type: "value",
            name: "金额",
            position: "left",
            min: 0,
          },
          {
            type: "value",
            name: "ROI",
            position: "right",
            min: 0,
          },
        ],
        series: [
          {
            name: "消费",
            type: "bar",
            data: [...this.seriesC],
            smooth: true,
          },
          {
            name: "成交金额",
            type: "bar",
            data: [...this.seriesT],
            smooth: true,
          },
          {
            name: "ROI",
            type: "line",
            data: [...this.seriesR],
            smooth: true,
          },
        ],
      });
    },
    initPie() {
      let _this = this;
      myPie = echarts.init(document.getElementById("pie-box"));
      myPie.setOption({
        tooltip: {
          trigger: "item",
        },
        title: {
          text: this.platform,
          top: "center",
          left: "center",
          textStyle: {
            color: "#3A8459",
            fontSize: "20px",
            fontWeight: "bold",
            lineHeight: 30,
            cursor: "pointer",
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,

            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              formatter: "{b|{b}：}  {d}%",
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: "#6E7079",
                  lineHeight: 22,
                  align: "center",
                },

                b: {
                  color: "#4C5058",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 33,
                },
              },
            },
            data: this.seriesData,
          },
        ],
      });
      myPie.on("click", function (params) {
        // 关系图的边被点击时此方法被回调。
        if (params.data.chiild) {
          _this.platform = params.name;
          myPie.setOption({
            title: {
              text: _this.platform,
            },
            series: [
              {
                data: params.data.chiild,
              },
            ],
          });
        } else {
          _this.platform = "";
          myPie.setOption({
            title: {
              text: _this.platform,
            },
            series: [
              {
                data: _this.seriesData,
              },
            ],
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ig-census {
  width: 100%;
  .sec-title {
    font-size: 21px;
    font-weight: bold;
    color: #1d1d1d;
  }
  .shadow {
    background: #ffffff;
    box-shadow: 0px 2px 9px 0px rgba(200, 200, 200, 0.29);
    border-radius: 14px;
  }
  .top-box {
    @include flex-between();
    .filter-box {
      @include flex-start();
      align-items: center;
      .label {
        @include flex-start();
        align-items: center;
        &:not(:last-child) {
          margin-right: 10px;
        }
        & > span {
          margin-right: 10px;
          font-size: 16px;
          color: #3d3d3d;
        }
        .time-box {
          @include flex-start(center);
        }
      }
    }
  }
  .content {
    .chart-box {
      min-height: 400px;
    }
    .pie-box {
      min-height: 500px;
      .back {
        width: 40px;
        height: 40px;
        background: url("~@img/marketing/backward.png");
        position: absolute;
        top: 20px;
        left: 30px;
      }
    }
    .table-box {
      min-height: 500px;
      @include flex-center();
    }
    .deal-box {
      @include flex-between();
      & > div {
        width: 49%;
      }
      & > .noData {
        width: 100%;
      }
    }
    .count-box {
      @include flex-between();
      & > p {
        width: 20%;
        height: 54px;
        background: linear-gradient(329deg, #3a8459 0%, #3a8459 0%, #559560 100%);
        box-shadow: 0px 2px 9px 0px rgba(200, 200, 200, 0.29);
        border-radius: 351px;
        line-height: 54px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        @include flex-center();
        & > img {
          height: 28px;
          margin-right: 10px;
        }
        &.btn-blue {
          background: linear-gradient(180deg, #199fd4 0%, #2eaadc 100%);
        }
        &.btn-pink {
          background: linear-gradient(328deg, #eb6327 0%, #ec692e 0%, #ffac80 100%);
        }
        &.btn-gray {
          background: #cbcbcb;
        }
      }
    }
    .map-box {
      @include flex-between();
      & > div {
        width: 49%;
      }
    }
  }
}
</style>
