<template>
  <div class="editComment">
    <div id="text-container" class="eidt-content"></div>
    <div id="toolbar-container"></div>
  </div>
</template>

<script>
import WangEditor from "wangeditor";
import { isBoolean } from "xe-utils";
let editor;
export default {
  data() {
    return {};
  },
  created() {
    if (editor) {
      editor.destroy();
      editor = null;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.createEditor();
    });
  },
  props: {
    resetEdit: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    resetEdit: {
      handler(newVal, oldVal) {
        if (newVal) editor.txt.html("");
      },
      immediate: true,
    },
  },
  methods: {
    createEditor() {
      let _this = this;
      editor = new WangEditor("#toolbar-container", "#text-container");
      editor.config.menus = ["foreColor", "backColor", "undo", "redo"];
      editor.config.zIndex = 8;
      editor.config.colors = [
        "#FF0000",
        "#FFC000",
        "#00B050",
        "#00B0F0",
        "#7030A0",
        "#000000",
        "#A6A6A6",
      ];
      editor.config.onchange = function (newHtml) {
        _this.$emit("eidtChange", newHtml);
      };
      editor.create();
    },
  },
  beforeRouteLeave() {
    // 销毁编辑器
    editor.destroy();
    editor = null;
  },
};
</script>

<style lang="scss" scoped>
.eidt-content {
  background: #f7f8fa;
  // border: 1px solid #e7e7e7;
  border-radius: 10px;
  position: relative;
  height: 120px;
  &:deep(.w-e-text-container) {
    background-color: transparent;
  }
}
</style>