<template>
  <div class="ig-myTable p-3">
    <el-dialog custom-class="customColumn" v-model="showCustomColumn" @close="closeCustomColumn" :show-close="false" width="640px">
      <div class="title mx-2 pt-2 pb-1 flex justify-between">
        <p>自定义列</p>
        <div class="flex">
          <p class="mr-2" @click="addAllColumn">添加全部列</p>
          <p @click="backDefalut">回复默认</p>
        </div>
      </div>
      <div class="content p-2">
        <p class="stitle">已添加 <span>支持拖动以调整顺序</span></p>
        <div id="addedList" class="added-list flex">
          <template v-for="(column, index) in columnsList" :key="index">
            <div class="item" :class="{ fixedItem: column.property == 'report_date' }" :data-id="column.title">
              <span>{{ column.title }}</span>
              <span class="icon ml-1" @click="doActionColumn(column, index, 1)"></span>
            </div>
          </template>
        </div>
        <p class="stitle">可添加 <span>点击“+”添加或拖动至已添加区域</span></p>
        <div id="addList" class="added-list add-list flex">
          <template v-for="(column, index) in notColumnList" :key="index">
            <div class="item" :data-id="column.title">
              <span> {{ column.title }}</span>
              <span class="icon ml-1" @click="doActionColumn(column, index, 2)"></span>
            </div>
          </template>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCustomColumn = false">取 消</el-button>
        <el-button type="primary" @click="showCustomColumn = false">确 定</el-button>
      </span>
    </el-dialog>

    <vxe-grid ref="xGrid2" class="table-box" v-bind="gridOptions2" max-height="500">
      <template #toolbar>
        <div class="mytools mb-2 flex justify-between">
          <p>详细数据</p>
          <div class="flex align-center">
            <p class="btn" @click="clickShowCustomColumn">自定义列</p>
            <p class="ml-2 pack" :class="{ open: !showChart }" @click="packChart">{{ showChart ? "收起" : "展开" }}图表</p>
          </div>
        </div>
      </template>
    </vxe-grid>
  </div>
</template>

<script>
import Sortable from "sortablejs";

export default {
  data() {
    return {
      dealReportData: [],
      showChart: true,
      showCustomColumn: false,
      columns2: null,
      columnsList: [],
      notColumnList: [],
      sortable2: null,
      addedSortable: null,
      addSortable: null,
      gridOptions2: {
        align: "center",
        cellClassName: "my-cell",
        border: "inner",
        columnKey: true,
        resizable: true,
        highlightHoverRow: true,
        class: "sortable-column-demo",
        scrollX: {
          enabled: false,
        },
        toolbarConfig: {
          custom: true,
        },
        showFooter: true,
        footerMethod: this.footerMethod,
        columns: [
          {
            field: "report_date",
            title: "时间日期",
            minWidth: 100,
            sortable: true,
            fixed: "left",
            type: "html",
          },
          {
            field: "to_show",
            title: "展现",
            minWidth: 80,
            sortable: true,
            type: "html",
          },
          {
            field: "to_click",
            title: "点击",
            minWidth: 80,
            sortable: true,
            type: "html",
          },
          {
            field: "click_rate",
            title: "点击率（%）",
            minWidth: 100,
            sortable: true,
            type: "html",
          },
          {
            field: "consume",
            title: "消费",
            minWidth: 80,
            sortable: true,
            type: "html",
          },
          {
            field: "cpc",
            title: "CPC",
            minWidth: 80,
            sortable: true,
            type: "html",
          },
          {
            field: "income",
            title: "进线",
            minWidth: 80,
            sortable: true,
            type: "html",
          },
          {
            field: "income_cost",
            title: "进线成本",
            minWidth: 100,
            sortable: true,
            type: "html",
          },
          {
            field: "income_rate",
            title: "进线率（%）",
            minWidth: 100,
            sortable: true,
            type: "html",
          },
          {
            field: "effect_income",
            title: "有效进线",
            minWidth: 100,
            sortable: true,
            type: "html",
          },
          {
            field: "effect_cost",
            title: "有效成本",
            minWidth: 100,
            sortable: true,
            type: "html",
          },
          {
            field: "effect_rate",
            title: "有效率（%）",
            minWidth: 100,
            sortable: true,
            type: "html",
          },
          {
            field: "trading_amount",
            title: "成交金额",
            minWidth: 100,
            sortable: true,
            visible: false,
            type: "html",
          },
          {
            field: "order_num",
            title: "订单量",
            minWidth: 80,
            sortable: true,
            visible: false,
            type: "html",
          },
          {
            field: "unit_price",
            title: "客单价",
            minWidth: 80,
            sortable: true,
            visible: false,
            type: "html",
          },
          {
            field: "roi",
            title: "ROI",
            minWidth: 80,
            sortable: true,
            visible: false,
            type: "html",
          },
        ],
        data: this.reportData,
      },
    };
  },
  created() {
    this.columnDrop2();
  },

  mounted() {
    this.$nextTick(() => {
      const { fullColumn } = this.$refs.xGrid2.getTableColumn();
      this.columns2 = fullColumn;
      this.columns2.forEach((item) => {
        item.visible ? this.columnsList.push(item) : this.notColumnList.push(item);
      });
    });
  },
  props: {
    valueType: { type: String, default: "" },
    compare: {
      type: Boolean,
      default: false,
    },
    reportCompareData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    reportData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    // valueType: {
    //   handler(newVal, oldVal) {
    //     // if (newVal == "month") {
    //     // }
    //   },
    // },
    compare: {
      handler(newVal, oldVal) {
        // this.compare = newVal;
        this.gridOptions2.showFooter = !newVal;
      },
    },
    reportCompareData: {
      handler(newVal, oldVal) {
        // this.reportCompareData = newVal;
        if (!this.compare) return;
        let type = "yyyy-MM-dd";
        if (this.valueType == "month") type = "yyyy-MM";
        newVal.forEach((item, index) => {
          [...Object.keys(item)].forEach((val) => {
            if (val == "report_date") {
              item[val] = this.formatReportDate(item[val], type);
            } else if (val == "roi") {
              item[val] = (item["trading_amount"] / item["consume"]).toFixed(2);
            } else if (val == "unit_price") {
              item[val] = item["order_num"] == 0 ? 0 : (item["trading_amount"] / item["order_num"]).toFixed(2);
            } else if (val == "income_cost") {
              item[val] = item["income"] == 0 ? 0 : (item["consume"] / item["income"]).toFixed(2);
            } else if (val == "click_rate") {
              item[val] = item["to_show"] == 0 ? 0 : ((item["to_click"] / item["to_show"]) * 100).toFixed(2);
            } else if (val == "cpc") {
              item[val] = item["to_click"] == 0 ? 0 : (item["consume"] / item["to_click"]).toFixed(2);
            } else if (val == "income_rate") {
              item[val] = item["to_click"] == 0 ? 0 : ((item["income"] / item["to_click"]) * 100).toFixed(2);
            } else if (val == "effect_cost") {
              item[val] = item["effect_income"] == 0 ? 0 : (item["consume"] / item["effect_income"]).toFixed(2);
            } else if (val == "effect_rate") {
              item[val] = item["income"] == 0 ? 0 : ((item["effect_income"] / item["income"]) * 100).toFixed(2);
            } else {
              let c = Number(item[val]);
              item[val] = Number.isInteger(c) ? c : c.toFixed(2);
            }
          });
        });
        this.compareTableData(newVal);
      },
      immediate: true,
    },
    reportData: {
      handler(newVal, oldVal) {
        // this.reportData = newVal;
        if (this.compare) return;
        let type = "yyyy-MM-dd";
        if (this.valueType == "month") type = "yyyy-MM";
        newVal.forEach((item, index) => {
          [...Object.keys(item)].forEach((val) => {
            if (val == "report_date") {
              item[val] = this.formatReportDate(item[val], type);
            } else if (val == "roi") {
              item[val] = (item["trading_amount"] / item["consume"]).toFixed(2);
            } else if (val == "unit_price") {
              // 客单价=成交金额/订单量
              item[val] = item["order_num"] == 0 ? 0 : (item["trading_amount"] / item["order_num"]).toFixed(2);
            } else if (val == "income_cost") {
              //进线成本=消费/进线
              item[val] = item["income"] == 0 ? 0 : (item["consume"] / item["income"]).toFixed(2);
            } else if (val == "click_rate") {
              //点击率=点击/展现
              item[val] = item["to_show"] == 0 ? 0 : ((item["to_click"] / item["to_show"]) * 100).toFixed(2);
            } else if (val == "cpc") {
              //cpc=消费/点击
              item[val] = item["to_click"] == 0 ? 0 : (item["consume"] / item["to_click"]).toFixed(2);
            } else if (val == "income_rate") {
              //进线率=进线/点击
              item[val] = item["to_click"] == 0 ? 0 : ((item["income"] / item["to_click"]) * 100).toFixed(2);
            } else if (val == "effect_cost") {
              item[val] = item["effect_income"] == 0 ? 0 : (item["consume"] / item["effect_income"]).toFixed(2);
            } else if (val == "effect_rate") {
              item[val] = item["income"] == 0 ? 0 : ((item["effect_income"] / item["income"]) * 100).toFixed(2);
            } else {
              let c = Number(item[val]);
              item[val] = Number.isInteger(c) ? c : c.toFixed(2);
            }
          });
        });
        this.dealReportData = newVal;
        this.gridOptions2.data = newVal;
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.sortable2) {
      this.sortable2.destroy();
    }
    if (this.addedSortable) {
      this.addedSortable.destroy();
    }
    if (this.addSortable) {
      this.addSortable.destroy();
    }
  },
  methods: {
    meanNum(list, field) {
      let count = 0;
      list.forEach((item) => {
        count += Number(item[field]);
      });
      return count / list.length;
    },
    sumNum(list, field) {
      let count = 0;
      list.forEach((item) => {
        count += Number(item[field]);
      });
      return count.toFixed(2);
    },
    footerMethod({ columns, data }) {
      return [
        // columns.map((column, columnIndex) => {
        //   if (columnIndex === 0) {
        //     return "平均";
        //   }
        //   if (["age", "sex"].includes(column.property)) {
        //     return this.meanNum(data, column.property);
        //   }
        //   return null;
        // }),
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }

          if (["consume", "income", "to_click", "to_show", "order_num", "trading_amount", "effect_income"].includes(column.property)) {
            return this.sumNum(data, column.property);
          }
          if (["income_cost", "income_rate", "effect_rate", "unit_price", "roi", "click_rate", "cpc", "effect_cost"].includes(column.property)) {
            switch (column.property) {
              case "income_cost":
                return (this.sumNum(data, "consume") / this.sumNum(data, "income")).toFixed(2);
              case "income_rate":
                return ((this.sumNum(data, "income") / this.sumNum(data, "to_click")) * 100).toFixed(2);
              case "effect_rate":
                return ((this.sumNum(data, "effect_income") / this.sumNum(data, "income")) * 100).toFixed(2);
              case "unit_price":
                return (this.sumNum(data, "trading_amount") / this.sumNum(data, "order_num")).toFixed(2);
              case "roi":
                return (this.sumNum(data, "trading_amount") / this.sumNum(data, "consume")).toFixed(2);
              case "click_rate":
                return ((this.sumNum(data, "to_click") / this.sumNum(data, "to_show")) * 100).toFixed(2);
              case "cpc":
                return (this.sumNum(data, "consume") / this.sumNum(data, "to_click")).toFixed(2);
              case "effect_cost":
                return (this.sumNum(data, "consume") / this.sumNum(data, "effect_income")).toFixed(2);
              default:
                return null;
            }
          }
        }),
      ];
    },
    // 关闭图标
    packChart() {
      this.showChart = !this.showChart;
      this.$emit("packChart", this.showChart);
    },
    // 表格比较数据
    compareTableData(newVal) {
      if (this.compare) {
        let type = "yyyy-MM-dd";
        if (this.valueType == "month") type = "yyyy-MM";
        let len = this.dealReportData.length;
        newVal.forEach((item, index) => {
          if (index >= len) {
            [...Object.keys(item)].forEach((val) => {
              if (val == "report_date") {
                item[val] = this.formatReportDate(item[val], type);
              }
            });
            return;
          }
          [...Object.keys(item)].forEach((val) => {
            if (val == "report_date") {
              item[val] = `
                <p>${this.formatReportDate(this.reportData[index][val], type)}</p>
                <p>${this.formatReportDate(item[val], type)}</p>
                <p>变化量</p>
                <p>变化率</p>
              `;
            } else {
              item[val] = `
          <p>${Number.isInteger(Number(this.reportData[index][val])) ? this.reportData[index][val] : Number(this.reportData[index][val]).toFixed(2)}</p>
          <p>${Number.isInteger(Number(item[val])) ? item[val] : Number(item[val]).toFixed(2)}</p>
          <p>${(parseFloat(this.reportData[index][val]) - parseFloat(item[val])).toFixed(2)}</p>
          <p style="color:#00BF5C">${parseFloat(item[val]) != 0 ? ((parseFloat(this.reportData[index][val]) / parseFloat(item[val]) - 1) * 100).toFixed(2) : item[val]}%</p>
      `;
            }
          });
        });
      } else {
        newVal.forEach((item, index) => {
          [...Object.keys(item)].forEach((val) => {
            if (val == "report_date") {
              item[val] = this.formatReportDate(item[val], type);
            }
          });
        });
      }
      this.gridOptions2.data = newVal;
      // this.$refs.xGrid2.loadData(newVal);
    },

    formatReportDate(e, type) {
      return this.$utils.formatDate(new Date(e).getTime() / 1000, type);
    },
    // 关闭自定义弹窗，销毁拖拽事件
    closeCustomColumn() {
      this.showCustomColumn = false;
      if (this.addedSortable) {
        this.addedSortable.destroy();
      }
      if (this.addSortable) {
        this.addSortable.destroy();
      }
    },
    // 拖拽改变列的变换
    changeTableColumn(item, newIndex, oldIndex) {
      const $table = this.$refs.xGrid2;
      const { fullColumn, tableColumn } = $table.getTableColumn();
      const targetThElem = item;
      const wrapperElem = targetThElem.parentNode;
      const newColumn = fullColumn[newIndex];
      if (newColumn.fixed) {
        // 错误的移动
        if (newIndex > oldIndex) {
          wrapperElem.insertBefore(targetThElem, wrapperElem.children[oldIndex]);
        } else {
          wrapperElem.insertBefore(wrapperElem.children[oldIndex], targetThElem);
        }
        return this.$message.error("固定列不允许移动");
      }
      // 转换真实索引
      const oldColumnIndex = $table.getColumnIndex(tableColumn[oldIndex]);
      const newColumnIndex = $table.getColumnIndex(tableColumn[newIndex]);
      // 移动到目标列
      const currRow = fullColumn.splice(oldColumnIndex, 1)[0];
      fullColumn.splice(newColumnIndex, 0, currRow);
      $table.loadColumn(fullColumn);
    },
    dargAction() {
      let _this = this;
      var g1 = document.getElementById("addedList");
      // var g1 = _this.$refs.addedList;
      var ops1 = {
        draggable: ".item",
        filter: ".fixedItem",
        group: { name: "columnlist", pull: true, put: true },
        //本列拖动
        onUpdate: ({ item, newIndex, oldIndex }) => {
          _this.changeTableColumn(item, newIndex, oldIndex);
        },
        onRemove: function (evt) {
          let arr = _this.addSortable.toArray();
          arr.forEach((temp) => {
            let c = _this.columns2.findIndex((val) => {
              return val.title == temp;
            });
            if (c !== -1) {
              _this.columns2[c].visible = false;
              _this.$set(_this.columns2, c, _this.columns2[c]);
            }
          });
          _this.$refs.xGrid2.refreshColumn();
        },
      };
      this.addedSortable = Sortable.create(g1, ops1);
      var g2 = document.getElementById("addList");
      // var g2 = this.$refs.addList;
      var ops2 = {
        draggable: ".item",
        group: { name: "columnlist", pull: true, put: true },
        //拖动结束
        onRemove: function (evt) {
          let arr = _this.addedSortable.toArray();
          arr.forEach((temp) => {
            let c = _this.columns2.findIndex((val) => {
              return val.title == temp;
            });
            if (c !== -1) {
              _this.columns2[c].visible = true;

              // _this.$set(_this.columns2, c, _this.columns2[c]);
            }
          });
          _this.$refs.xGrid2.refreshColumn();
          //获取拖动后的排序
          let item = evt.item;
          let newIndex = evt.newIndex;
          const $table = _this.$refs.xGrid2;
          const { fullColumn, tableColumn } = $table.getTableColumn();
          let oldIndex = fullColumn.findIndex((val) => {
            return val.title == item.dataset.id;
          });
          let a = $table.getColumnIndex(tableColumn[newIndex]);
          let b = $table.getColumnIndex(tableColumn[oldIndex]);
          _this.changeTableColumn(item, a, b);
        },
      };
      this.addSortable = Sortable.create(g2, ops2);
    },
    clickShowCustomColumn() {
      this.showCustomColumn = !this.showCustomColumn;
      setTimeout(() => {
        this.dargAction();
      }, 500);
    },
    addAllColumn() {
      this.columns2.forEach((item) => {
        item.visible = true;
      });
      this.$refs.xGrid2.refreshColumn();
      this.columnsList = [];
      this.notColumnList = [];
      let arr = this.$refs.xGrid2.getColumns();
      arr.forEach((item) => {
        item.visible ? this.columnsList.push(item) : this.notColumnList.push(item);
      });
    },
    backDefalut() {
      this.columns2.forEach((item) => {
        if (item.property == "roi" || item.property == "unit_price" || item.property == "order_num" || item.property == "trading_amount") {
          item.visible = false;
        } else {
          item.visible = true;
        }
      });
      this.$refs.xGrid2.refreshColumn();
      this.columnsList = [];
      this.notColumnList = [];
      // let arr = this.$refs.xGrid2.getColumns();
      this.columns2.forEach((item) => {
        item.visible ? this.columnsList.push(item) : this.notColumnList.push(item);
      });
    },
    doActionColumn(item, index, type) {
      item.visible = !item.visible;
      this.$refs.xGrid2.refreshColumn();
      const $table = this.$refs.xGrid2;
      const { fullColumn } = $table.getTableColumn();
      let c = fullColumn.findIndex((val) => {
        return val.title == item.title;
      });
      if (type == 1) {
        this.columnsList.splice(index, 1);
        this.notColumnList.splice(c, 0, item);
      } else {
        this.notColumnList.splice(index, 1);
        this.columnsList.splice(c, 0, item);
      }
    },
    toolClick(e) {},

    columnDrop2() {
      this.$nextTick(() => {
        const $table = this.$refs.xGrid2;
        this.sortable2 = Sortable.create($table.$el.querySelector(".body--wrapper>.vxe-table--header .vxe-header--row"), {
          handle: ".vxe-header--column:not(.col--fixed)",
          onEnd: ({ item, newIndex, oldIndex }) => {
            this.changeTableColumn(item, newIndex, oldIndex);
          },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$vxe-font-size: 10px;
$vxe-font-color: #333333;
$vxe-primary-color: #00bf5c;
$vxe-table-font-color: $vxe-font-color;
$vxe-table-border-color: #ebebeb;
$vxe-table-border-radius: 4px;
.ig-myTable {
  background: #ffffff;
  box-shadow: 0px 2px 9px 0px rgba(200, 200, 200, 0.29);
  border-radius: 10px;
  &:deep(.vxe-table--render-default .vxe-body--column),
  &:deep(.vxe-table--render-default .vxe-footer--column),
  &:deep(.vxe-table--render-default .vxe-header--column) {
    line-height: normal;
    color: #333333;
    font-size: 10px;
  }
  &:deep(.vxe-table--render-default.size--mini .vxe-body--column:not(.col--ellipsis)),
  &:deep(.vxe-table--render-default.size--mini .vxe-footer--column:not(.col--ellipsis)),
  &:deep(.vxe-table--render-default.size--mini .vxe-header--column:not(.col--ellipsis)) {
    padding: 16px 0;
  }
  .table-box {
    .my-cell {
      line-height: auto;
      color: #333333;
      font-size: 10px;
    }
  }

  .mytools {
    & > p {
      font-size: 16px;
      color: #333333;
    }
    .btn {
      cursor: pointer;
      width: 80px;
      height: 24px;
      border: 1px solid #888888;
      border-radius: 12px;
      font-size: 12px;
      color: #888888;
      line-height: 24px;
      text-align: center;
    }
    .pack {
      font-size: 12px;
      color: #005cd1;
      padding-right: 16px;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 10px;
        height: 8px;
        background: url("~@img/report/arrow.png") no-repeat;
        background-size: cover;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .open {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  .customColumn {
    &:deep(.el-dialog__header) {
      display: none;
    }

    &:deep(.el-dialog) {
      box-shadow: 0px 2px 9px 0px rgba(200, 200, 200, 0.29);
      border-radius: 10px;

      .el-dialog__footer {
        font-size: 16px;
      }
    }
    .title {
      border-bottom: 1px solid #888888;
      & > p {
        font-size: 16px;
        color: #333333;
      }
      & > div {
        font-size: 14px;
        cursor: pointer;
        color: #005cd1;
      }
    }
    .content {
      .stitle {
        font-size: 14px;
        color: #333333;
        & > span {
          font-size: 10px;
          color: #888888;
        }
      }
      .added-list {
        margin-top: 16px;
        flex-wrap: wrap;
        .item {
          background: #005cd1;
          border-radius: 10px;
          font-size: 12px;
          color: #ffffff;
          padding: 4px 10px;
          margin-right: 16px;
          margin-bottom: 16px;
          .icon {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: url("~@img/report/close.png") no-repeat;
            background-size: cover;
            cursor: pointer;
          }
        }
      }
      .add-list {
        .item {
          background: #f5f5f5;
          color: #888888;
          .icon {
            background: url("~@img/report/add.png") no-repeat;
          }
        }
      }
    }
    .dialog-footer {
      padding: 0 20px 10px;
      @include flex-end();
    }
  }
}
</style>
