<template>
  <div class="column-line px-1 py-3">
    <div class="top-menu">
      <el-select v-model="type" placeholder="请选择" class="mr-1" size="mini" @change="typeChange">
        <el-option v-for="item in optionsType" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled || item.value == typeTo"> </el-option>
      </el-select>
      <el-select v-model="typeTo" placeholder="请选择" size="mini" @change="typeToChange">
        <el-option v-for="item in optionsType" :key="item.value" :label="item.label" :value="item.value" :disabled="(item.disabled && item.value !== 0) || item.value == type">
        </el-option>
      </el-select>
    </div>
    <template v-if="reportData.length != 0">
      <div style="width: 100%; height: 360px" id="columnLine"></div>
    </template>
    <div v-show="reportData.length == 0" style="width: 100%; height: 360px" class="flex align-center justify-center position-absolute top-0 left-0">
      <img src="@img/report/nodata.png" height="200" />
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
let myChart = null;
export default {
  data() {
    return {
      // myChart: null,
      income: [0, 0, 0],
      consume: [0, 0, 0],
      day: ["1月", "2月", "3月"],
      dayCompare: [],
      report: [],
      type: "consume",
      type_label: "消费",
      typeTo: 0,
      typeTo_label: "无",
      optionsType: [
        {
          value: 0,
          label: "无",
          disabled: true,
        },
        {
          value: "consume",
          label: "消费",
        },
        {
          value: "to_show",
          label: "展现",
        },
        {
          value: "to_click",
          label: "点击",
        },
        {
          value: "click_rate",
          label: "点击率",
        },
        {
          value: "cpc",
          label: "cpc",
        },
        {
          value: "income",
          label: "进线",
        },
        {
          value: "income_cost",
          label: "进线成本",
        },
        {
          value: "income_rate",
          label: "进线率",
        },
        {
          value: "effect_income",
          label: "有效进线",
        },
        {
          value: "effect_cost",
          label: "有效成本",
        },
        {
          value: "effect_rate",
          label: "有效率",
        },
        {
          value: "trading_amount",
          label: "成交金额",
        },
        {
          value: "order_num",
          label: "订单量",
          disabled: true,
        },
        {
          value: "unit_price",
          label: "客单价",
          disabled: true,
        },
        {
          value: "roi",
          label: "ROI",
          disabled: true,
        },
      ],
      chartData: {
        consume: [], //消费
        to_click: [], //点击
        click_rate: [], //点击率
        cpc: [],
        effect_cost: [], //有效成本
        effect_income: [], //有效进线
        effect_rate: [], //有效率
        income: [], //进线
        income_cost: [], //进线成本
        income_rate: [], //进线率
        to_show: [], //展现
        trading_amount: [], //成交金额
        order_num: [], //订单量
        unit_price: [], //客单价
        roi: [], //roi
      },
      chartCompareData: {
        consume: [], //消费
        to_click: [], //点击
        click_rate: [], //点击率
        cpc: [],
        effect_cost: [], //有效成本
        effect_income: [], //有效进线
        effect_rate: [], //有效率
        income: [], //进线
        income_cost: [], //进线成本
        income_rate: [], //进线率
        to_show: [], //展现
        trading_amount: [], //成交金额
        order_num: [], //订单量
        unit_price: [], //客单价
        roi: [], //roi
      },
      yAxis: [],
      series: [],
      legend: [],
      yAxisCompare: [],
      seriesCompare: [],
      legendCompare: [],
    };
  },
  props: {
    reportData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    reportCompareData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    valueType: {
      type: String,
      default: "day",
    },
    compare: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  methods: {
    typeToChange(e) {
      let data = this.optionsType.find((val) => {
        return val.value == e;
      });
      this.typeTo_label = data.label;

      if (e == 0) {
        if (myChart) myChart.dispose();
        this.series.splice(1, 1);
        this.yAxis.splice(1, 1);
        this.legend.splice(1, 1);
        this.seriesCompare.splice(1, 1);
        this.yAxisCompare.splice(1, 1);
        this.legendCompare.splice(1, 1);
        this.chart();
      } else {
        this.yAxis[1] = {
          type: "value",
          name: this.typeTo_label,
          min: 0,
        };
        this.series[1] = {
          name: this.typeTo_label,
          type: "line",
          xAxisIndex: 0,
          yAxisIndex: 1,
          smooth: true,
          data: this.chartData[this.typeTo],
        };
        this.legend[1] = {
          name: this.typeTo_label,
          icon: "rect",
        };

        if (this.compare) {
          this.seriesCompare[1] = {
            name: this.typeTo_label,
            type: "line",
            xAxisIndex: 1,
            yAxisIndex: 1,
            smooth: true,
            data: this.chartCompareData[this.typeTo],
            itemStyle: {
              normal: {
                lineStyle: {
                  type: "dotted", //'dotted'虚线 'solid'实线
                },
              },
            },
          };
        }
      }
      myChart.setOption({
        legend: {
          data: [...this.legend, ...this.legendCompare],
        },
        series: [...this.series, ...this.seriesCompare],
        yAxis: [...this.yAxis, ...this.yAxisCompare],
      });
    },
    typeChange(e) {
      let data = this.optionsType.find((val) => {
        return val.value == e;
      });
      this.type_label = data.label;
      this.series[0] = {
        name: this.type_label,
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        smooth: true,
        data: this.chartData[this.type],
      };
      this.yAxis[0] = {
        type: "value",
        name: this.type_label,
        min: 0,
      };
      this.legend[0].name = this.type_label;
      if (this.compare) {
        this.seriesCompare[0] = {
          name: this.type_label,
          type: "line",
          xAxisIndex: 1,
          yAxisIndex: 0,
          smooth: true,
          data: this.chartCompareData[this.type],
          itemStyle: {
            normal: {
              lineStyle: {
                type: "dotted", //'dotted'虚线 'solid'实线
              },
            },
          },
        };
        this.yAxisCompare[0] = {
          type: "value",
          name: this.type_label,
          min: 0,
        };
      }
      myChart.setOption({
        legend: {
          data: [...this.legend, ...this.legendCompare],
        },
        series: [...this.series, ...this.seriesCompare],
        yAxis: [...this.yAxis, ...this.yAxisCompare],
      });
    },
    chart() {
      // 基于准备好的dom，初始化echarts实例
      if (!document.getElementById("columnLine")) {
        return;
      }
      // if(document.getElementById("columnLine"))
      myChart = echarts.init(document.getElementById("columnLine"));
      myChart.setOption({
        barGap: 0,
        color: ["#FF7B33", "#005CD1", "#60CF82"],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255, 255,255, 1)",
          extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);",
          textStyle: {
            color: "#333333",
          },
          axisPointer: {
            type: "cross",
          },
          formatter: (params, ticket, callback) => {
            var htmlStr = "";
            // 分两组数组，循环数据
            let arr = [[], []];
            params.forEach((val) => {
              if (val.color == "#FF7B33") {
                arr[0].push(val);
              } else {
                arr[1].push(val);
              }
            });
            arr.forEach((item, index) => {
              for (let i = 0; i < item.length; i++) {
                let param = item[i];
                let xName = param.name; //x轴的名称
                let seriesName = param.seriesName; //图例名称
                let value = param.value; //y轴值
                let color = param.color; //图例颜色
                // htmlStr += xName + '<br />'
                let str = `<p style="margin-top:10px">${seriesName}<p>`;
                if (param.axisIndex == 0) {
                  htmlStr += `
                <div style="width:200px;padding:0 10px">
                  ${i == 0 ? str : ""}
                  <div style="width:100%">
                    <div style="display:flex;justify-content: space-between;">
                        <p>
                          <span style="display:inline-block;width:14px;height:0;border-bottom:3px solid ${color};margin-bottom:3px;margin-right:10px;"></span>
                          ${xName}
                        </p>
                        <p style="text-algin:right">${value}</p>
                    </div>
                  </div>
                </div>
              `;
                } else {
                  htmlStr += `
                <div style="width:200px;padding:0 10px">
                  ${i == 0 ? str : ""}
                  <div style="width:100%">
                    <div style="display:flex;justify-content: space-between;">
                        <p>
                          <span style="display:inline-block;width:14px;height:0;border-bottom:3px dashed ${color};margin-bottom:3px;margin-right:10px;"></span>
                          ${xName}
                        </p>
                        <p style="text-algin:right">${value}</p>
                    </div>
                  </div>
                </div>
              `;
                }
              }

              if (item.length == 2) {
                htmlStr += `
                <div style="display:flex;justify-content: space-between;width:200px;padding:0 10px">
                  <p>变化率</p>
                  <p>${((item[0].value / item[1].value - 1) * 100).toFixed(2)}%</p>
                </div>`;
              }
              if (item.length == 2 && index == 0) {
                htmlStr += `
                  <div style="height:1px;background: #EBEBEB;margin-top:10px"></div>
                  `;
              }
            });

            return htmlStr;
          },
        },

        grid: {
          left: "2%",
          right: "2%",
          containLabel: true,
        },
        legend: {
          x: "left",
          y: "top",
          itemWidth: 14,
          itemHeight: 2,
          data: [...this.legend, ...this.legendCompare],
        },
        xAxis: [
          {
            type: "category",
            data: this.day,
            gridIndex: 0,
            axisPointer: {
              type: "line",
            },
            boundaryGap: true,
          },
          {
            type: "category",
            data: this.dayCompare,
            axisPointer: {
              type: "line",
            },
            boundaryGap: true,
          },
        ],
        yAxis: [...this.yAxis, ...this.yAxisCompare],
        series: [...this.series, ...this.seriesCompare],
      });

      // 绘制图表
    },
    defaultChartData() {
      this.chartData = {
        consume: [], //消费
        to_click: [], //点击
        click_rate: [], //点击率
        cpc: [],
        effect_cost: [], //有效成本
        effect_income: [], //有效进线
        effect_rate: [], //有效率
        income: [], //进线
        income_cost: [], //进线成本
        income_rate: [], //进线率
        to_show: [], //展现
        trading_amount: [], //成交金额
        order_num: [], //订单量
        unit_price: [], //客单价
        roi: [], //roi
      };
    },
    defaultChartCompareData() {
      this.chartCompareData = {
        consume: [], //消费
        to_click: [], //点击
        click_rate: [], //点击率
        cpc: [],
        effect_cost: [], //有效成本
        effect_income: [], //有效进线
        effect_rate: [], //有效率
        income: [], //进线
        income_cost: [], //进线成本
        income_rate: [], //进线率
        to_show: [], //展现
        trading_amount: [], //成交金额
        order_num: [], //订单量
        unit_price: [], //客单价
        roi: [], //roi
      };
    },
    setNub(nub1, nub2) {
      if (nub1 == 0 || nub2 == 0) return 0;
      return (parseFloat(nub1) / parseFloat(nub2)).toFixed(2);
    },
  },
  watch: {
    valueType: {
      handler(newVal, oldVal) {
        // this.valueType = newVal;
        this.optionsType.forEach((val) => {
          if (val.value == "roi" || val.value == "unit_price" || val.value == "order_num") newVal == "month" ? (val.disabled = false) : (val.disabled = true);
        });
      },
    },
    reportCompareData: {
      handler(newVal, oldVal) {
        // this.reportCompareData = newVal;
        this.defaultChartCompareData();
        if (myChart) myChart.dispose();
        if (newVal.length == 0) {
          this.dayCompare = [];
          this.yAxisCompare = [];
          this.seriesCompare = [];
          this.legendCompare = [];
          this.chart();
          return;
        }
        let day = [];
        newVal.forEach((item) => {
          [...Object.keys(this.chartCompareData)].forEach((val) => {
            if (val == "roi") {
              this.chartCompareData[val].push((item["trading_amount"] / item["consume"]).toFixed(2));
            } else if (val == "unit_price") {
              this.chartCompareData[val].push((item["trading_amount"] / item["order_num"]).toFixed(2));
            } else if (val == "income_cost") {
              this.chartCompareData[val].push(item["income"] == 0 ? 0 : (item["consume"] / item["income"]).toFixed(2));
            } else if (val == "click_rate") {
              this.chartCompareData[val].push(item["to_show"] == 0 ? 0 : ((item["to_click"] / item["to_show"]) * 100).toFixed(2));
            } else if (val == "cpc") {
              this.chartCompareData[val].push(item["to_click"] == 0 ? 0 : (item["consume"] / item["to_click"]).toFixed(2));
            } else if (val == "income_rate") {
              this.chartCompareData[val].push(item["to_click"] == 0 ? 0 : ((item["income"] / item["to_click"]) * 100).toFixed(2));
            } else if (val == "effect_cost") {
              this.chartCompareData[val].push(item["effect_income"] == 0 ? 0 : (item["consume"] / item["effect_income"]).toFixed(2));
            } else if (val == "effect_rate") {
              this.chartCompareData[val].push(item["income"] == 0 ? 0 : ((item["effect_income"] / item["income"]) * 100).toFixed(2));
            } else {
              let c = Number(item[val]);
              this.chartCompareData[val].push(Number.isInteger(c) ? c : c.toFixed(2));
            }
          });
          if (this.valueType == "day") {
            let date = new Date(item.report_date);
            day.push(this.$utils.formatDate(date.getTime() / 1000, "M-dd"));
          }
        });
        this.dayCompare = day;
        this.seriesCompare = [
          {
            name: this.type_label,
            type: "line",
            xAxisIndex: 1,
            yAxisIndex: 0,
            smooth: true,
            data: this.chartCompareData[this.type],
            itemStyle: {
              normal: {
                lineStyle: {
                  type: "dotted", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
        ];

        if (this.typeTo !== 0) {
          this.seriesCompare[1] = {
            name: this.typeTo_label,
            type: "line",
            xAxisIndex: 1,
            yAxisIndex: 1,
            smooth: true,
            data: this.chartCompareData[this.typeTo],
            itemStyle: {
              normal: {
                lineStyle: {
                  type: "dotted", //'dotted'虚线 'solid'实线
                },
              },
            },
          };
        }
        this.chart();
      },
      immediate: true,
    },
    reportData: {
      handler(newVal, oldVal) {
        // this.reportData = newVal;
        this.defaultChartData();
        if (myChart) {
          myChart.dispose();
        }
        this.report = newVal;

        let day = [];
        newVal.forEach((item) => {
          [...Object.keys(this.chartData)].forEach((val) => {
            if (val == "roi") {
              this.chartData[val].push((item["trading_amount"] / item["consume"]).toFixed(2));
            } else if (val == "unit_price") {
              this.chartData[val].push((item["trading_amount"] / item["order_num"]).toFixed(2));
            } else if (val == "income_cost") {
              this.chartData[val].push(item["income"] == 0 ? 0 : (item["consume"] / item["income"]).toFixed(2));
            } else if (val == "click_rate") {
              this.chartData[val].push(item["to_show"] == 0 ? 0 : ((item["to_click"] / item["to_show"]) * 100).toFixed(2));
            } else if (val == "cpc") {
              this.chartData[val].push(item["to_click"] == 0 ? 0 : (item["consume"] / item["to_click"]).toFixed(2));
            } else if (val == "income_rate") {
              this.chartData[val].push(item["to_click"] == 0 ? 0 : ((item["income"] / item["to_click"]) * 100).toFixed(2));
            } else if (val == "effect_cost") {
              this.chartData[val].push(item["effect_income"] == 0 ? 0 : (item["consume"] / item["effect_income"]).toFixed(2));
            } else if (val == "effect_rate") {
              this.chartData[val].push(item["income"] == 0 ? 0 : ((item["effect_income"] / item["income"]) * 100).toFixed(2));
            } else {
              let c = Number(item[val]);
              this.chartData[val].push(Number.isInteger(c) ? c : c.toFixed(2));
              // this.chartData[val].push(item[val]);
            }
          });
          // consume.push(item.consume);

          if (this.valueType == "day") {
            let date = new Date(item.report_date);
            day.push(this.$utils.formatDate(date.getTime() / 1000, "M-dd"));
          } else if (this.valueType == "month") {
            let date = new Date(item.report_date);
            day.push(this.$utils.formatDate(date.getTime() / 1000, "yyyy-MM月"));

            // day.push(item.months + "月");
          } else {
            day.push(item.weeks + "周");
          }
        });
        this.day = day;
        // 添加数据
        this.yAxis = [
          {
            type: "value",
            name: this.type_label,
            min: 0,
          },
        ];
        this.series = [
          {
            name: this.type_label,
            type: "line",
            xAxisIndex: 0,
            yAxisIndex: 0,
            smooth: true,
            data: this.chartData[this.type],
          },
        ];
        this.legend = [
          {
            name: this.type_label,
            icon: "rect",
          },
        ];

        if (this.typeTo !== 0) {
          this.yAxis[1] = {
            type: "value",
            name: this.typeTo_label,
            min: 0,
          };
          this.series[1] = {
            name: this.typeTo_label,
            type: "line",
            xAxisIndex: 0,
            yAxisIndex: 1,
            smooth: true,
            data: this.chartData[this.typeTo],
          };
          this.legend[1] = {
            name: this.typeTo_label,
            icon: "rect",
          };
        }
        this.$nextTick(() => {
          this.chart();
        });
      },
      immediate: true,
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
.column-line {
  width: 100%;
  font-size: 16px;
  position: relative;

  .top-menu {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 99;

    &:deep(.el-select) {
      width: 140px;
    }
  }
}
</style>
