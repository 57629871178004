<template>
  <div
    class="myTextarea-box"
    :class="{ 'marketing-textarea': type == 'marketing' }"
  >
    <div class="valid_state my-2">
      <van-radio-group v-model="invalidType" direction="horizontal">
        <van-radio
          v-for="(item, index) in validityStatus"
          :name="item.id"
          :key="index"
        >
          <template #icon="props">
            <!-- <img
              class="img-icon"
              :src="props.checked ? activeIcon : inactiveIcon"
            /> -->
            <span
              class="icon"
              :class="props.checked ? 'activeIcon' : 'inactiveIcon'"
            ></span>
          </template>
          <p class="item">{{ item.name }}</p>
        </van-radio>
      </van-radio-group>
    </div>
    <div class="textarea-content">
      <van-field
        v-model="message"
        rows="6"
        autosize
        type="textarea"
        placeholder="请输入正文"
      />
      <!-- <editcomment
        :resetEdit="resetEdit"
        @eidtChange="eidtChange"
      ></editcomment> -->
    </div>
    <div class="other-box mt-2">
      <div class="other"></div>
      <div class="submit-btn" @click="submit">发布</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import editcomment from "./editcomment.vue";

export default {
  components: { editcomment },
  data() {
    return {
      // resetEdit: false,
      invalidType: 0,
      address: false,
      keyboard: false,
      message: "",
      imgurl: "",
      refresh: true,
    };
  },
  props: {
    type: {
      type: String,
      default: "default",
    },
    poolId: {
      type: Number,
      default: 0,
    },
    state: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    state: {
      handler(newVal, oldVal) {
        this.invalidType = newVal;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      validityStatus: (state) => {
        return [...state.constant.validity_status];
      },
    }),
  },
  created() {},
  mounted() {},
  methods: {
    // eidtChange(e) {
    //   this.message = e;
    // },
    submit() {
      if (!this.invalidType)
        return this.$notify({ type: "danger", message: "请选择线索有效性" });
      if (!this.message)
        return this.$notify({ type: "danger", message: "请输入跟进内容" });

      // this.resetEdit = true;
      this.$emit("submit", {
        content: this.message,
        invalidType: this.invalidType,
      });
      this.message = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.myTextarea-box {
  position: relative;
  .valid_state {
    .item {
      font-size: $biggestFontSize;
      color: #424141;
    }
    .icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: #f7f8fa;
      border: 1px solid #d5d5d5;
      border-radius: 50%;
    }
    .activeIcon {
      border: 3px solid #4892fe;
      position: relative;
      &::after {
        content: "";
        width: 10px;
        height: 10px;
        background: #4892fe;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .textarea-content {
    // background: #f7f8fa;
    // border: 1px solid #e7e7e7;
    // border-radius: 20px;
    // position: relative;
    // height: 300px;
    // overflow: hidden;
  }
}
.marketing-textarea {
  .textarea-content {
    &:deep(.van-cell) {
      position: relative;
      background: #f5f5f5;
      border-radius: 15px;
      font-size: $biggestFontSize;
      color: #424141;
      line-height: normal;
    }
  }
  .other-box {
    @include flex-end;
    .submit-btn {
      width: 140px;
      height: 50px;
      background: #0085fe;
      border-radius: 25px;
      line-height: 50px;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>