<template>
  <div class="flow-pb">
    <div class="flow">
      <div id="myChart" :style="{ width: '100%', height: '400px' }"></div>
    </div>
    <div class="flow-main">
      <div class="flow-box">
        <div class="flow-list" v-for="(item, index) in reportDetail" :key="index">
          <div class="flow-l">
            <span class="bg" :style="{ background: color[index] }"></span>
            <span class="name">{{ item.platform_name }}</span>
          </div>
          <span class="flow-r"
            >{{ item.report_sum ? parseFloat(item.report_sum).toFixed(2) : 0 }} {{ title == "有效率" || title == "点击率" || title == "进线率" ? "%" : "" }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    reportDetail: Array,
    title: String,
    nub: Number,
  },
  data() {
    return {
      s: 0,
      datas: [],
      fromData: [],
      color: ["#0882FF", "#6147FF", "#4AC16F", "#E73A3A", "#59F6A3", "#FF97A6", "#FFE373", "#53EDEF", "#FF9000", "#D8B37D", "#E89D85"],
      xAxisData: [],
      seriesData: [],
    };
  },
  mounted() {
  
  },
  methods: {
    echar() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        title: {
          text: this.title + "：" + this.nub,
        },
        color: ["#0882FF", "#6147FF", "#4AC16F", "#E73A3A", "#59F6A3", "#FF97A6", "#FFE373", "#53EDEF", "#FF9000", "#D8B37D", "#E89D85"],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ",
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 10,
        //   data: this.fromData
        // },

        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "30",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.datas,
          },
        ],
      });
    },
    echartBar() {
      var myChart = echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        color: ["#3398DB"],
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.xAxisData,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "直接访问",
            type: "bar",
            barWidth: "60%",
            data: this.seriesData,
          },
        ],
      });
    },
  },
  watch: {
    nub(val) {
    },
    reportDetail: {
      handler(newVal, oldVal) {
        if (newVal.length != 0) {
          let datas = [];
          let fromData = [];
          let s = 0;
          if (this.title == "有效率" || this.title == "点击率" || this.title == "进线率") {
            let xAxisData = [];
            let seriesData = [];
            newVal.forEach((item) => {
              item.report_avg = item.report_avg ? item.report_avg : item.report_sum;
              item.report_avg = parseFloat(item.report_avg) * 100;

              xAxisData.push(item.platform_name);
              seriesData.push(parseFloat(item.report_avg ? item.report_avg : 0));
              datas.push({
                value: parseFloat(item.report_avg ? item.report_avg : 0),
                name: item.platform_name,
              });
              fromData.push(item.platform_name);
              item.report_sum = parseFloat(item.report_avg ? item.report_avg : 0).toFixed(2) + "%";
              s = s + parseFloat(item.report_avg ? item.report_avg : 0);
            });
            this.xAxisData = xAxisData;
            this.seriesData = seriesData;

            s = (s / newVal.length).toFixed(2) + "%";
            this.datas = datas;
            this.fromData = fromData;
            this.echartBar();
          } else {
            newVal.forEach((item) => {
              datas.push({
                value: parseFloat(item.report_sum ? item.report_sum : 0),
                name: item.platform_name,
              });
              fromData.push(item.platform_name);
              s = s + parseFloat(item.report_sum ? item.report_sum : 0);
            });

            this.s = s;
            this.datas = datas;
            this.fromData = fromData;
            this.echar();
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
.flow-pb {
  padding-bottom: 70px;
}

.flow {
  height: 400px;
}

.flow-main {
  box-sizing: border-box;
  padding: 0 12px;

  .flow-box {
    box-sizing: border-box;
    border-radius: 16px;
    background: #f7f8fa;
    padding: 25px 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .flow-list {
      width: 40%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: 10px 0;

      .flow-l {
        display: flex;
        align-items: center;

        .bg {
          width: 10px;
          height: 10px;
          background: #0882ff;
          display: block;
          margin-right: 10px;
        }

        .name {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #4f4f4f;
        }
      }

      .flow-r {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #3278d2;
      }
    }
  }
}
</style>
