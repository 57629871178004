<template>
  <div
    class="ig-report mt-2 border-box"
    v-loading="loading"
    element-loading-text="拼命上传中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="upload flex justify-center align-center" @click="upload" v-if="permit">
      <img src="@img/report/daoru.png" />
    </div>
    <!-- <p style="font-size: 12px;width: 100%;">{{tokenaa}}</p> -->
    <div class="home-hread">
      <div class="pb-2">
        <el-row :gutter="20">
          <el-col :span="18">
            <div class="home-hread-top flex justify-between align-center border-box pt-2 px-2">
              <el-row :gutter="20">
                <el-col :span="8">
                  <div class="grid-content bg-purple flex justify-between align-center">
                    <span class="d-block l-test">分公司</span>
                    <el-select v-model="sourcesValue" placeholder="请选择分公司" class="flex-1" size="small" @change="sourcesChange">
                      <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="10">
                  <div class="grid-content bg-purple-light">
                    <div class="grid-content bg-purple flex flex justify-between align-center">
                      <span class="d-block l-test">投放平台</span>
                      <el-select v-model="value" placeholder="请选择投放平台" class="flex-1" size="small" @change="optionsChange">
                        <el-option v-for="item in sources" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                      </el-select>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <el-radio-group v-model="version" @change="changeVersion">
                    <el-radio-button label="2">新版</el-radio-button>
                    <el-radio-button label="1">旧版</el-radio-button>
                  </el-radio-group>
                </el-col>
              </el-row>
            </div>

            <div class="home-hread-bottom border-box px-2">
              <div class="l-list mt-2 flex align-center">
                <span class="l-test">时间单位</span>
                <el-select v-model="valueType" placeholder="请选择时间单位" class="flex-1" size="small" @change="typeChange">
                  <el-option
                    v-for="item in optionsType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="compare && (item.value == 'week' || item.value == 'month')"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="l-list range-box mt-2 position-relative">
                <template v-if="valueType == 'day'">
                  <el-date-picker
                    v-model="compareStart"
                    @change="changeRange"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="YYYY-MM-DD"
                    format="YYYY/MM/DD"
                    :shortcuts="shortcuts"
                    :disabled-date="disabledDate"
                  >
                  </el-date-picker>
                  <el-checkbox class="px-1" v-model="compare" @change="changeCompare">比较</el-checkbox>
                  <el-date-picker
                    v-if="compare"
                    v-model="compareEnd"
                    @change="changeCompareRangge"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="YYYY-MM-DD"
                    format="YYYY/MM/DD"
                    unlink-panels
                    :shortcuts="shortcuts"
                    :disabled-date="disabledDate"
                  >
                  </el-date-picker>
                </template>
                <template v-if="valueType == 'month'">
                  <el-date-picker
                    v-model="compareStart"
                    @change="changeRange"
                    type="monthrange"
                    range-separator="~"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="YYYY-MM-DD"
                    format="YYYY/MM"
                    :shortcuts="shortcuts"
                    :disabled-date="disabledDate"
                  >
                  </el-date-picker>

                  <el-checkbox class="px-1" v-model="compare" @change="changeCompare">比较</el-checkbox>
                </template>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="mt-2" @click="drawer = true">
              <div id="myImport" :style="{ width: '100%', height: '123px' }"></div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="home-hread-content mt-2" v-show="showChart">
        <column-line :reportData="reportData" :reportCompareData="reportCompareData" :valueType="valueType" :compare="compare"></column-line>
      </div>
    </div>
    <div class="mt-2">
      <my-table :compare="compare" :valueType="valueType" :reportData="reportData" :reportCompareData="reportCompareData" @packChart="packChart"></my-table>
    </div>

    <el-drawer class="drawer" direction="ttb" title="导入未导入" v-model="drawer" :with-header="false">
      <div class="position-relative">
        <list :datas="importList"></list>
        <list :datas="notImportList"></list>
        <span class="position-absolute xiangshang" @click="hideDrawer">
          <img src="@img/report/arrow-up.png" alt="" />
        </span>
      </div>
    </el-drawer>

    <el-drawer class="drawer" direction="ttb" title="有效流" v-model="showEffectrate" :with-header="false">
      <div class="position-relative">
        <list :datas="effectrate" :right="incomingLine.effect_rate" @deptId="deptId"></list>
        <span class="position-absolute xiangshang" @click="hideDrawer">
          <img src="@img/report/arrow-up.png" alt="" />
        </span>
      </div>
    </el-drawer>

    <el-drawer class="drawer" direction="ttb" title="流量统计" v-model="showFlow" :with-header="false">
      <div class="position-relative">
        <flow :reportDetail="reportDetail" :title="title" :nub="nub"></flow>
        <span class="position-absolute xiangshang flow" @click="hideDrawer">
          <img src="@img/report/arrow-up.png" alt="" />
        </span>
      </div>
    </el-drawer>

    <!-- 模板下载 -->
    <el-dialog title="导入选项" v-model="dialogVisible" width="400px" :before-close="handleClose">
      <div class="dialog-box px-3 pt-4 border-box">
        <div class="dialog-box-title">上传选项</div>
        <div class="border-box px-4">
          <div class="dialog-box-list mt-2 text-center c-p position-relative position-relative" v-for="(item, index) in tableType" :key="index" @click="mImport(index)">
            <!-- <el-upload class="upload-demo" accept="excel" :action="action" :data="datatype" :on-success="onSuccess"
              :on-error='onError' :on-progress='onProgress' :file-list="fileList"> -->
            <!-- <el-button size="small" type="primary">点击上传</el-button> -->
            导入{{ item }}
            <input type="file" id="people-export" ref="inputer" @change="fileUpload" class="position-absolute top-0" />
            <!-- </el-upload> -->
            <!-- <input type="file" name="file" class="upload__input position-absolute" @change="change($event)"> -->
          </div>
        </div>
      </div>
      <div class="dialog-box px-3 py-4 border-box">
        <div class="dialog-box-title">模板下载</div>
        <div class="border-box px-4">
          <div class="dialog-box-list mt-2 text-center dialog-box-list-b c-p" v-for="(item, index) in tableType" :key="index" @click="mUpload(index)">下载{{ item }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import list from "./list.vue";
import flow from "./flow.vue";
import columnLine from "./columnLine.vue";
import myTable from "./myTable.vue";
import * as echarts from "echarts";
const loading = null;
export default {
  name: "Home",

  data() {
    return {
      version: "2",
      showChart: true,
      compare: false, //对比
      compareStart: null,
      compareEnd: null,
      startsWeekTime: "",
      endsWeekTime: "",
      action: "api/ypd/report/importrp",
      loading: false,
      shortcuts: [
        {
          text: "最近一周",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "最近一个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "最近三个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
        {
          text: "最近一年",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            return [start, end];
          },
        },
      ],
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      startWeekTime: {
        firstDayOfWeek: 1,
      },
      endWeekTime: {
        disabledDate: (time) => {
          let beginDateVal = this.startWeek;
          if (beginDateVal) {
            return (
              time.getTime() < new Date(beginDateVal).getTime() - 1000 * 86400 ||
              time.getTime() > new Date(beginDateVal).getTime() + 1000 * 86400 * 7 * 12 ||
              time.getTime() > Date.now()
            );
          }
          return time.getTime() > Date.now();
        },
        firstDayOfWeek: 1,
      },
      startMonthTime: {
        disabledDate: (time) => {
          // let endDateVal = this.endDay;
          // if (endDateVal) {
          //   return time.getTime() > new Date(endDateVal).getTime()  || time.getTime() > Date.now();
          // }
          return time.getTime() > Date.now();
        },
        firstDayOfWeek: 1,
      },
      endMonthTime: {
        disabledDate: (time) => {
          let beginDateVal = this.startMonth;
          if (beginDateVal) {
            return time.getTime() < new Date(beginDateVal).getTime() || time.getTime() > new Date(beginDateVal).getTime() + 1000 * 86400 * 30 * 12 || time.getTime() > Date.now();
          }
          return time.getTime() > Date.now();
        },
        firstDayOfWeek: 1,
      },
      startDay: "",
      endDay: "",
      startWeek: "",
      endWeek: "",
      startMonth: "",
      endMonth: "",
      datatype: {
        type: "day",
      },
      fileList: [],
      mImporttype: "",
      tableType: ["日报表", "月报表"],
      valuetime: [],
      value1: "",
      value2: "",
      valueType: "day",
      datas: {
        title: "已导入",
        lists: [
          {
            name: "广东分公司",
            time: "2020-11-06 14:20",
          },
          {
            name: "广东分公司",
            time: "2020-11-06 14:20",
          },
          {
            name: "广东分公司",
            time: "2020-11-06 14:20",
          },
        ],
      },
      optionsType: [
        {
          value: "day",
          label: "分日",
        },
        // {
        //   value: "week",
        //   label: "分周",
        // },
        {
          value: "month",
          label: "分月",
        },
      ],
      dialogVisible: false,
      showFlow: false,
      drawer: false,
      sources: [],
      sourcesValue: "",
      options: [],
      value: "",
      reportData: [],
      reportCompareData: [],
      incomingLine: {},
      permit: false,
      importList: {},
      notImportList: {},
      effectrate: {},
      showEffectrate: false,
      report: "",
      reportDetail: [],
      title: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      fullscreenLoading: false,
      token: "",
      tokenaa: localStorage.getItem("token"),
      nub: 0,
    };
  },
  created() {
    // if (!this.$route.query.code) {
    //     this.getCode();
    // }
  },
  filters: {
    fixed(val) {
      return val;
    },
  },
  methods: {
    changeVersion() {
      this.reportlist();
    },
    mytest(e) {
      console.log(e);
    },
    packChart(e) {
      this.showChart = e;
    },
    //方法
    fileUpload(event) {
      // 上传文件
      let file = event.target.files;
      let formData = new FormData();
      formData.append("type", this.datatype.type);
      formData.append("token", localStorage.getItem("token"));
      formData.append("file", file[0]);
      this.loading = true;
      // 文件上传
      this.$axios({
        method: "POST",
        url: this.action,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          // 'Authorization' : localStorage.getItem('token')
        },
      })
        .then((res) => {
          this.$notify({
            title: "成功",
            message: "上传成功",
            type: "success",
          });
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$notify({
            title: "警告",
            message: e.response.data.message,
            type: "warning",
          });
        });
      event.target.value = "";
    },
    getToken() {
      // if (!this.$route.query.code) return ;
      // code: 200  正确  判断用户是否能登录
      return new Promise((reslove, reject) => {
        if (localStorage.getItem("token")) {
          // localStorage.setItem('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9zYWRtaW4ueW91cHVkYW8uY29tXC9hcGlcL3lwZFwvcmVwb3J0XC9sb2dpbiIsImlhdCI6MTYxNjczMDc2NywiZXhwIjoxNjE2NzM0MzY3LCJuYmYiOjE2MTY3MzA3NjcsImp0aSI6IlF4SGprM3VBd1oxN2Z3WFYiLCJzdWIiOjQzMSwicHJ2IjoiZjJhZDM3YjcxNGFiMTIxMDJhYzA1MGU3ZTEzM2U2MzIzZmJjODczYyJ9.z9kH4TOD8Zm0TECvotwRzbY17J13s9-aLRisaFSDy8E');
          if (navigator.userAgent.match(/(iPhone|Android.*Mobile)/) || navigator.userAgent.match(/MSIE [6,7,8,9]/)) {
            this.$router.push("/mhome");
          }
          reslove("");
        } else {
          this.$api
            .getToken({
              code: this.$route.query.code,
            })
            .then((res) => {
              localStorage.setItem("token", res.data.token);
              this.token = res.data.token;
              if (navigator.userAgent.match(/(iPhone|Android.*Mobile)/) || navigator.userAgent.match(/MSIE [6,7,8,9]/)) {
                this.$router.push("/mhome");
              }
              reslove(res.data.token);
            })
            .catch((err) => {
              this.token = err.response;
              this.$router.push({
                path: "/about",
                query: {
                  msg: "该用户暂无权限",
                },
              });
            });
        }
      });
    },
    // 时间管理
    // startDay:'',
    // endDay:'',
    // startWeek:'',
    // endWeek:'',
    // startMonth:'',
    // endMonth:'',
    changeRange(e) {
      if (!e) return;
      if (this.compare) {
        this.changeCompare(true);
      }
      this.startDay = e[0];
      this.endDay = e[1];
      this.reportlist();
      // this.reportlistDay();
      this.getImportlist();
    },
    changeCompareRangge(e) {
      console.log(e);
      this.reportCompareList(e[0], e[1]);
    },
    changeCompare(e) {
      if (!e) {
        this.reportCompareData = [];
        this.reportlist();
        return;
      }
      if (this.valueType == "week" || this.valueType == "month") {
        this.valueType = "day";
      }
      if (!this.compareStart) {
        //默认当前12天
        var startDate = new Date(); //获取今天日期
        startDate.setDate(startDate.getDate() - 12);
        var endDate = new Date();
        this.compareStart = [this.$utils.formatDate(startDate.getTime() / 1000, "yyyy-MM-dd"), this.$utils.formatDate(endDate.getTime() / 1000, "yyyy-MM-dd")];
      }
      // 计算时间差
      let c = new Date(this.compareStart[1]).getTime() - new Date(this.compareStart[0]).getTime();
      let end = this.$utils.formatDate(new Date(new Date(this.compareStart[0]).getTime() - 86400000).getTime() / 1000, "yyyy-MM-dd");
      let start = this.$utils.formatDate(new Date(new Date(end).getTime() - c).getTime() / 1000, "yyyy-MM-dd");
      this.compareEnd = [start, end];
      this.reportlist();
      this.reportCompareList(start, end);
    },

    // 上传成功
    onSuccess() {
      this.$notify({
        title: "成功",
        message: "上传成功",
        type: "success",
      });
      this.loading = false;

      this.reportlist();
      // this.reportlistDay();
      if (this.compare) this.changeCompare(true);
      this.getImportlist();
    },
    onError(e) {
      this.loading = false;
      this.$notify({
        title: "警告",
        message: JSON.parse(e.message).message,
        type: "warning",
      });
    },
    onProgress() {
      this.loading = true;
    },
    // 数据来源
    sourcesChange(e) {
      this.getPlatform();
      this.getCompany();
      this.reportlist();
      if (this.compare) this.changeCompare(true);
      this.getImportlist();
    },
    // 平台来源
    optionsChange(e) {
      this.getPlatform();
      this.getCompany();
      this.reportlist();
      if (this.compare) this.changeCompare(true);
      this.getImportlist();
    },
    typeChange(e) {
      // 选择类型 判断显示 日月周
      this.getPlatform();
      this.getCompany();
      this.reportlist();
      if (this.compare) this.changeCompare(true);
      this.getImportlist();
    },
    // 月
    getMonth(e) {
      console.log(e);
    },
    // 上传文件
    change(event) {
      let file = event.target.files;
      let formData = new FormData();
      formData.append("category", "settingPic");
      formData.append("type", this.mImporttype);
      formData.append("file", file[0]);
      // 文件上传{}
      this.$axios
        .post("http://sadmin.youpudao.com/api/ypd/report/importrp", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response);
        });
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    hideDrawer() {
      this.showFlow = false;
      this.drawer = false;
      this.showEffectrate = false;
    },
    // 上传
    upload() {
      this.dialogVisible = true;
    },
    // mImport 导入
    mImport(index) {
      //console.log('下载', index); //day|week|month
      // this.mImporttype = index
      if (index == 0) {
        this.mImporttype = "day";
        this.datatype.type = "day";
      } else {
        this.mImporttype = "month";
        this.datatype.type = "month";
      }
    },
    // 下载
    mUpload(index) {
      let data = "";
      switch (index) {
        case 0:
          data = "每日数据报表.xlsx";
          break;
        case 1:
          data = "每月数据报表.xlsx";
          break;
          // case 2:
          //   data = "每周数据报表.xlsx";

          break;
        default:
          return;
      }

      window.location.href = "http://sadmin.youpudao.com/static/file/report/" + data;
    },
    getPlatform() {
      this.$api
        .getPlatform()
        .then((res) => {
          res.data.unshift({
            id: "",
            name: "全部",
          });
          this.sources = res.data;
        })
        .catch((err) => {});
    },
    getTags() {
      return new Promise((resolve, reject) => {
        resolve("222");
      });
    },
    // 列表数据
    getCompany() {
      this.$api
        .getCompany()
        .then((res) => {
          res.data.unshift({
            id: "",
            name: "全部",
          });
          this.options = res.data;
        })
        .catch((err) => {});
    },
    getTime() {
      var startDate = new Date(); //获取今天日期
      startDate.setDate(startDate.getDate() - 1);
      var endDate = new Date();
      var startWeek = new Date(); //获取今天日期
      startWeek.setDate(startWeek.getDate() - 7);
      var startMonth = new Date(); //获取今天日期
      startMonth.setDate(startMonth.getDate() - 30);

      let startTime = "";
      let endTime = "";
      if (this.valueType == "day") {
        startTime = this.startDay || this.$utils.formatDate(startDate.getTime() / 1000, "yyyy-MM-dd");
        endTime = this.endDay || this.$utils.formatDate(startDate.getTime() / 1000, "yyyy-MM-dd");
      } else if (this.valueType == "week") {
        startTime = this.startWeek || this.$utils.formatDate(startWeek.getTime() / 1000, "yyyy-MM-dd");
        endTime = this.endWeek || this.$utils.formatDate(startWeek.getTime() / 1000, "yyyy-MM-dd");
      }
      if (this.valueType == "month") {
        startTime = this.startMonth || this.$utils.formatDate(startMonth.getTime() / 1000, "yyyy-MM-dd");
        endTime = this.endMonth || this.$utils.formatDate(startMonth.getTime() / 1000, "yyyy-MM-dd");
      }

      let data = {
        type: this.valueType,
        startTime: startTime,
        endTime: endTime,
        deptId: this.sourcesValue,
        platform: this.value,
      };
      return data;
    },
    reportCompareList(start, end) {
      let data = {
        type: this.valueType,
        startTime: start,
        endTime: end,
        deptId: this.sourcesValue,
        platform: this.value,
        version: this.version,
      };
      // if (data.deptId) {
      //   let item = this.options.find((val) => {
      //     return val.id == data.deptId;
      //   });
      //   data.poolId = item.pool_id;
      // }

      this.$api.reportlist(data).then((res) => {
        this.reportCompareData = res.data;
      });
    },
    reportlist() {
      let startTime = "";
      let endTime = "";
      if (this.compareStart) {
        startTime = this.compareStart[0];
        endTime = this.compareStart[1];
      } else {
        var startDate = new Date(); //获取今天日期
        startDate.setDate(startDate.getDate() - 12);
        var endDate = new Date();

        var startWeek = new Date(); //获取今天日期
        startWeek.setDate(startWeek.getDate() - 7 * 12);

        var startMonth = new Date(); //获取今天日期
        startMonth.setDate(startMonth.getDate() - 30 * 12);

        startTime = this.startDay || this.$utils.formatDate(startDate.getTime() / 1000, "yyyy-MM-dd");
        endTime = this.endDay || this.$utils.formatDate(endDate.getTime() / 1000, "yyyy-MM-dd");

        if (this.valueType == "month") {
          startTime = this.startMonth || this.$utils.formatDate(startMonth.getTime() / 1000, "yyyy-MM-dd");
          endTime = this.endMonth || this.$utils.formatDate(endDate.getTime() / 1000, "yyyy-MM-dd");
        }
        // 图表  同一天的话 开始时间 取前7天
        if (startTime == endTime) {
          var start = new Date(startTime); //获取今天日期
          start.setDate(start.getDate() - 7);
          startTime = this.$utils.formatDate(start.getTime() / 1000, "yyyy-MM-dd");
        }
      }

      let data = {
        type: this.valueType,
        startTime: startTime,
        endTime: endTime,
        deptId: this.sourcesValue,
        platform: this.value,
        version: this.version,
      };
      // if (data.deptId) {
      //   let item = this.options.find((val) => {
      //     return val.id == data.deptId;
      //   });
      //   data.poolId = item.pool_id;
      // }

      this.$api
        .reportlist(data)
        .then((res) => {
          this.reportData = res.data;
        })
        .catch((err) => {});
    },

    getIncome(report, nub, title) {
      this.report = report;
      let data = this.getTime();
      data.report = report;
      // data.startTime = '' ;
      // data.endTime= '' ;
      this.showEffectrate = true;
      this.$api.reportCumulative(data).then((res) => {
        this.effectrate = {
          title: title,
          test: "合计：" + parseFloat(nub).toFixed(2),
          lists: res.data,
        };
      });
    },
    getEffectIncome() {
      // let data = this.getTime();
      // this.showEffectrate = true;
      //  this.$api.incomedetail(data).then(res=>{
      //    this.effectrate = {
      //      title: '有效进线量',
      //      test: '合计：' + this.incomingLine.effect_income,
      //      lists: res.data
      //    }
      //  })
    },
    getEffectrate(report, nub, title) {
      if (!title) title = "点击价格";
      this.report = this.setReport(report);
      let data = this.getTime();
      data.report = this.setReport(report);
      this.showEffectrate = true;
      this.$api.reportRatio(data).then((res) => {
        res.data.forEach((item) => {
          item.report_avg = item.report_avg ? item.report_avg : 0;
        });
        this.effectrate = {
          title: title,
          test: nub,
          lists: res.data,
        };
      });
    },
    // 设置report类型
    setReport(type) {
      switch (type) {
        case "click_rate":
          return "to_click/to_show";
          break;
        case "effect_rate":
          return "effect_income/income";
          break;
        case "income_cost":
          return "consume/income";
          break;
        case "effect_cost":
          return "consume/effect_income";
          break;
        case "income_rate":
          return "income/to_click";
          break;
        case "cpc":
          return "consume/to_click";
          break;
        default:
        case "roi":
          return "trading_amount/consume";
          break;
          return "konga";
      }
    },
    // 点击列表
    deptId(id, title, nub) {
      this.nub = parseFloat(nub);
      this.showFlow = true;
      let data = this.getTime();
      data.deptId = id;
      data.report = this.report;
      if (title == "有效率" || title == "点击率" || title == "进线率") {
        this.$api.ratiodetail(data).then((res) => {
          this.reportDetail = res.data;
          this.title = title;
        });
      } else {
        this.$api.reportDetail(data).then((res) => {
          this.reportDetail = res.data;
          this.title = title;
        });
      }
    },
    setChart(imp, notimp) {
      let self = this;
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("myImport"));
      // 绘制图表
      myChart.setOption({
        color: ["#0882FF", "#cccccc"],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
          series: {
            hoverAnimation: false,
          },
        },
        // radius : '80%',
        series: [
          {
            hoverAnimation: false,
            name: "是否导入",
            type: "pie",
            radius: ["100%", "88%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 30,
            },
            label: {
              show: false,
              position: "center",
              normal: {
                show: true,
                position: "center",
                color: "#4c4a4a",
                formatter: "{total|" + imp + "}" + "{nub|" + "/" + (imp + notimp) + "}" + "\n\r" + "{active|查看导入}",
                rich: {
                  total: {
                    fontSize: 30,
                    fontFamily: "微软雅黑",
                    color: "#005CD1",
                    fontweight: "bold",
                  },
                  nub: {
                    fontSize: 16,
                    fontFamily: "微软雅黑",
                    color: "#cccccc",
                    fontweight: "bold",
                  },
                  active: {
                    fontFamily: "微软雅黑",
                    fontSize: 14,
                    color: "#005CD1",
                    lineHeight: 10,
                  },
                },
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "15",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: imp,
                name: "导入",
              },
              {
                value: notimp,
                name: "未导入",
              },
            ],
          },
        ],
      });
    },

    // 权限 是否能下载
    importpower() {
      this.$api.importpower().then((res) => {
        this.permit = res.data.permit;
      });
    },
    // 导入列表
    getImportlist() {
      let data = this.getTime();
      this.$api.importlist(data).then((res) => {
        this.importList = {
          title: "已导入",
          lists: res.data.import_list,
        };
        this.notImportList = {
          title: "未导入",
          lists: res.data.not_import_list,
        };

        // 导入数据图表
        this.setChart(res.data.import_list.length, res.data.not_import_list.length);
      });
    },
  },
  components: {
    list,
    flow,
    columnLine,
    myTable,
  },
  mounted() {
    if (navigator.userAgent.match(/(iPhone|Android.*Mobile)/) || navigator.userAgent.match(/MSIE [6,7,8,9]/)) {
      this.$router.push("/mhome");
    }
    this.getPlatform();
    this.getCompany();
    this.reportlist();
    this.importpower();
    this.getImportlist();
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
#people-export {
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
}
.ig-report {
  &:deep(.el-dialog .el-dialog__header) {
    display: none;
  }

  &:deep(.el-loading-mask) {
    z-index: 3000 !important;
  }

  &:deep(.el-upload) {
    width: 100%;
    height: 100%;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  &:deep(.el-upload-list) {
    display: none;
  }

  .upload__input {
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &:deep(.el-date-editor .el-range-input) {
    font-size: 12px;
    color: #888888;
  }

  &:deep(.el-input--suffix .el-input__inner) {
    padding-right: 10px;
  }

  &:deep(.el-select .el-input__wrapper) {
    border: none;
    border-radius: 12px;
    font-size: 14px;
    color: #888888;
    background-color: #f5f5f5;
    box-shadow: none !important;
  }

  // 弹窗
  &:deep(.el-dialog) {
    // width: 400px !important;
    border-radius: 20px;

    &:deep(.el-dialog__header) {
      padding: 20px;
      background: #2d89ff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      &:deep(.el-dialog__title) {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }

      .el-dialog__headerbtn .el-dialog__close {
        color: #ffffff;
      }
    }
  }

  &:deep(.el-dialog__body) {
    padding: 0 !important;
  }

  &:deep(.dialog-box) {
    .dialog-box-title {
      border-left: 2px solid #2d89ff;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #606470;
      padding-left: 10px;
    }

    .dialog-box-list {
      height: 45px;
      background: #2d89ff;
      border-radius: 8px;
      line-height: 45px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }

    .dialog-box-list-b {
      background: #e9f3ff;
      color: #0e78ff;
    }
  }

  .upload {
    width: 60px;
    height: 60px;
    background: linear-gradient(135deg, #ffad2d 0%, #ff6114 98%);
    box-shadow: 0px 2px 9px 0px rgba(200, 200, 200, 0.29);

    border-radius: 50%;
    position: fixed;
    top: 80%;
    right: 100px;
    z-index: 999999;
  }

  .xiangshang {
    font-size: 32px;
    color: #d4e7ff;
    margin: 0 auto;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    width: 40px;
    & > img {
      width: 40px;
    }
  }

  .flow {
    bottom: 10px;
  }

  &:deep(.el-drawer) {
    width: 750px;
    margin: 0 auto;
    &.btt {
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      max-height: 85vh !important;
      min-height: 30vh !important;
      height: auto !important;
      overflow: auto;
    }
    &.ttb {
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      max-height: 85vh !important;
      min-height: 30vh !important;
      height: auto !important;
      overflow: auto;
    }
  }

  &:deep(.el-popup-parent--hidden) {
    overflow: auto !important;
  }

  .l-test {
    display: block;
    text-align: justify;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    margin-right: 15px;
    width: 68px;
  }

  .home-hread {
    width: 100%;

    & > div {
      background: #ffffff;
      box-shadow: 0px 2px 9px 0px rgba(200, 200, 200, 0.29);
      border-radius: 10px;
    }

    .home-hread-top {
      .top-l {
        .l-test {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          margin-right: 15px;
        }

        input {
        }
      }
    }

    .home-hread-bottom {
      .l-list {
        // justify-content: space-evenly;

        .l-list-span {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          margin-right: 15px;
        }
      }

      .range-box {
        &:deep(.el-input__inner) {
          width: 184px;
          height: 26px;
          line-height: 26px;
          border: 1px solid #888888;
          border-radius: 20px;
          padding: 0 10px;
          box-shadow: none;
        }
        &:deep(.el-date-editor .el-range__icon) {
          display: none;
        }

        &:deep(.el-range-separator) {
          padding: 0;
          line-height: 26px;
          font-size: 12px;
          color: #888888;
        }

        &:deep(.el-date-editor .el-range__close-icon) {
          line-height: 26px;
        }

        &:deep(.el-date-editor .el-range-input) {
          font-size: 12px;
          color: #888888;
          width: 80%;
        }
        &:deep(.el-range-editor.is-active, .el-range-editor.is-active:hover) {
          box-shadow: none;
        }
      }
    }

    .home-hread-content {
      height: 360px;
    }

    .home-hread-foot {
      .foot-box {
        background: #f7f8fa;
        border-radius: 16px;
        padding: 15px 0;

        .list {
          width: 33%;
          border-left: 1px solid #c4c6cc;

          .t-test {
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #005cd1;
          }

          .b-test {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #8e8e8e;
          }

          &:nth-child(1) {
            border: none;
          }
        }
      }
    }
  }

  .text-color {
    color: #ee3f3f !important;
  }
}
</style>
